import {useEffect, useMemo, useState} from 'react';
import {I18nFunction} from '../i18n/i18n';
import {useAsyncMemo} from '../util/hooks';
import {Formy, FormyBase, isFormyErroneous} from './Formy';
import {FormyErrors, FormyEventListener, FormyI, FormyMode, FormyOnSubmit} from './index';

// section=true listens for changes to a column Fk, as well as all subfields within.
export function useFormyError<F, Fk extends keyof F>(formy: FormyI<F>, key: Fk, section?: boolean): boolean {
  const [value, setValue] = useState(formy.getError(key));
  useEffect(() => {
    // useState(formy.getError(key)) will set the value only on the initial mount.
    // In case formy is recreated, then useFormyValue won't return the updated value, because it will no longer set initial value, even if it changed.
    // Because formy was recreated, the watchers below also can't detect and update the changes.
    // We need to call the setter to make sure the value updates.
    setValue(formy.getError(key));
    if (section) {
      async function updateValue(field: keyof F, errors: FormyErrors<F>) {
        if (field == key) {
          // We copy the object so as to trigger updates.
          const res = errors[key];
          setValue(typeof res == 'object' ? {...res} : res);
        }
      }

      formy.addOnChangeListener(updateValue);
      return () => formy.removeOnChangeListener(updateValue);
    } else {
      function update() {
        setValue(getter());
      }

      const getter = formy.watchError(key, update);

      return () => formy.unwatch(update);
    }
  }, [formy, key, section]);
  return isFormyErroneous(value);
}

// section=true listens for changes to a column Fk, as well as all subfields within.
export function useFormyValue<F, Fk extends keyof F>(formy: undefined, key: Fk, section?: boolean): undefined;
export function useFormyValue<F, Fk extends keyof F>(formy: FormyI<F>, key: Fk, section?: boolean): F[Fk];
export function useFormyValue<F, Fk extends keyof F>(
  formy: undefined | FormyI<F>,
  key: Fk,
  section?: boolean,
): undefined | F[Fk];
export function useFormyValue<F, Fk extends keyof F>(
  formy: undefined | FormyI<F>,
  key: Fk,
  section?: boolean,
): undefined | F[Fk] {
  const [value, setValue] = useState(formy?.getValue(key));
  useEffect(() => {
    // useState(formy.getValue(key)) will set the value only on the initial mount.
    // In case formy is recreated, then useFormyValue won't return the updated value, because it will no longer set initial value, even if it changed.
    // Because formy was recreated, the watchers below also can't detect and update the changes.
    // We need to call the setter to make sure the value updates.
    setValue(formy?.getValue(key));
    if (!formy) {
      return;
    }
    if (section) {
      async function updateValue(field: keyof F, values: F) {
        if (field == key) {
          // We copy the object so as to trigger updates.
          setValue({...values[key]});
        }
      }

      formy.addOnChangeListener(updateValue);
      return () => formy.removeOnChangeListener(updateValue);
    } else {
      function update() {
        setValue(getter());
      }

      const getter = formy.watchValue(key, update);

      return () => formy.unwatch(update);
    }
  }, [formy, key, section]);
  return value;
}

export function useFormyMode<F>(formy: FormyI<F>): null | FormyMode {
  const [mode, setMode] = useState<FormyMode>();
  useEffect(() => {
    function update() {
      setMode(getter.mode);
    }

    const getter = formy.watchState('mode', update);
    update();

    return () => formy.unwatch(update);
  }, [formy]);
  return mode ?? null;
}

// A convenience function that makes sure that the formy object is not re-created; and that its callbacks
// are updated, in case they need to be bound to a new closure scope.
// It takes a factory function that calculates the initialValues, which is called if and only if `key` changes.
export function useFormy<F extends object>(
  mode: FormyMode,
  initialValuesFactory: () => Promise<F>,
  t: I18nFunction,
  onSubmit: FormyOnSubmit<F>,
  validate: (x: F) => FormyErrors<F>,
  // Sometimes, we do want to reinitialize Formy; this key can be used for that purpose, and should also
  // be passed into the top level of the component. See also test "pattern for Formy with hooks works".
  // Otherwise, null can be passed here.
  key: null | string,
): undefined | Formy<F> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formy = useAsyncMemo(async () => new Formy(mode, await initialValuesFactory(), t, onSubmit, validate), [key]);
  useEffect(() => {
    if (!formy) {
      return;
    }
    (formy as unknown as FormyPrivates<F>).onSubmit = onSubmit;
    (formy as unknown as FormyPrivates<F>).validate = validate;
  }, [formy, onSubmit, validate]);

  return formy;
}

// A sync version of useFormy, for use with synchronous initialValuesFactory.
export function useSyncFormy<F extends object>(
  mode: FormyMode,
  initialValuesFactory: () => F,
  t: I18nFunction,
  onSubmit: FormyOnSubmit<F>,
  validate: (x: F) => FormyErrors<F>,
  key: null | string,
): Formy<F> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formy = useMemo(() => new Formy(mode, initialValuesFactory(), t, onSubmit, validate), [key]);
  useEffect(() => {
    if (!formy) {
      return;
    }
    (formy as unknown as FormyPrivates<F>).onSubmit = onSubmit;
    (formy as unknown as FormyPrivates<F>).validate = validate;
  }, [formy, onSubmit, validate]);

  return formy;
}

interface FormyPrivates<F> {
  onSubmit: FormyBase<F>['onSubmit'];
  validate: FormyBase<F>['validate'];
}

export function useFormyHasChangedValues<F extends Object>(formy: FormyI<F>) {
  const [hasChangedValues, setHasChangedValues] = useState<boolean>(false);
  useEffect(() => {
    const l: FormyEventListener<F> = () => {
      setHasChangedValues(formy.hasChangedValues());
    };
    formy.addOnChangeListener(l);
    return () => formy.removeOnChangeListener(l);
  }, [formy]);
  return hasChangedValues;
}

export function useFormyHasChangedValue<F extends Object, K extends keyof F>(formy: FormyI<F>, key: K) {
  const [hasChangedValue, setHasChangedValue] = useState<boolean>(false);
  useEffect(() => {
    const l: FormyEventListener<F> = changedKey => {
      if (changedKey === key) {
        setHasChangedValue(formy.hasChangedValue(key));
      }
    };
    formy.addOnChangeListener(l);
    return () => formy.removeOnChangeListener(l);
  }, [formy, key]);
  return hasChangedValue;
}
