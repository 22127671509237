// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentina',
  'AUS': 'Australia',
  'AUT': 'Austria',
  'Accept': 'Accettare',
  'AcceptOrDecline': 'Accetta/declina',
  'AddAnotherArea': 'Aggiungi un\'altra area',
  'AddAssignee': 'Aggiungi assegnatario',
  'AddAttachments': 'Aggiungi allegati',
  'AddCause': 'Aggiungi una causa',
  'AddClaim': 'Aggiungi reclamo',
  'AddCost': 'Aggiungi un costo',
  'AddCrop': 'Aggiungi tipo di coltura',
  'AddEditSampleShape': 'Disegna l\'area interessata sulla mappa',
  'AddEditor': 'Aggiungi redattore',
  'AddEditors': 'Aggiungi editori',
  'AddEditorsOneGroup': 'Aggiungi editori agli elementi in un solo portfolio',
  'AddFarm': 'Aggiungi azienda agricola',
  'AddFarmInfo': 'Tieni presente che per aggiungere nuovi/ulteriori appezzamenti a un\'azienda agricola ESISTENTE, è necessario prima cercarla per nome dell\'azienda agricola o riferimento dell\'azienda agricola nei campi corrispondenti sottostanti.',
  'AddField': 'Aggiungi nuovo campo',
  'AddFieldShape': 'Aggiungi forma campo',
  'AddFieldShapes': 'Aggiungi forme di campo',
  'AddFieldsHarvests': 'Aggiungi campi e raccolti',
  'AddFromFileManager': 'Aggiungi da File Manager',
  'AddFromPhotos': 'Aggiungi da foto',
  'AddGuaranteedLossCause': 'Aggiungi una causa di perdita garantita',
  'AddHarvest': 'Aggiungi nuovo raccolto',
  'AddLossCause': 'Aggiungi una causa di perdita',
  'AddNewHarvest': 'Crea un nuovo raccolto',
  'AddNewHarvestFor': 'Crea un nuovo raccolto per',
  'AddNewSubparcel': 'Aggiungi una nuova sottopartita',
  'AddOrEditFields': 'Aggiungi/modifica campi',
  'AddPhoto': 'Aggiungi foto',
  'AddPhotoError': 'Aggiunta della foto non riuscita',
  'AddPolicy': 'Aggiungi nuovo contratto',
  'AddRecipient': 'Aggiungere il destinatario',
  'AddSample': 'Aggiungi campione',
  'AddSamplePhoto': 'Aggiungi foto al campione',
  'AddSubplotId': 'Aggiungi ID sottotrama',
  'AddUnguaranteedLossCause': 'Aggiungi una causa di perdita non garantita',
  'AddUser': 'Aggiungi utente',
  'AddVarietyHarvest': 'Aggiungi varietà',
  'AddedBy': 'Aggiunto da',
  'AddedGroups': 'Gruppi aggiunti',
  'AddedOn': 'Aggiunto a',
  'AdditionalInfo': 'Informazioni addizionali',
  'AdditionalQuestions': 'Domande aggiuntive',
  'AdvancedOptions': 'Opzioni avanzate',
  'AffectedArea': 'Area colpita',
  'AffectedCrop': 'Coltura interessata',
  'AffectedCrops': 'Colture colpite',
  'AffectedCropsRequiredToDeclareLoss': 'È necessario selezionare almeno una coltura colpita per aggiungere le perdite dichiarate.',
  'AffectedField': 'Campo colpito',
  'All': 'Tutto',
  'AllSynced': 'Tutto sincronizzato',
  'AllTime': 'Sempre',
  'AlsoInheritedFrom': 'Ereditato anche da',
  'Amount': 'Importo ($)',
  'AppVersionDeprecated': 'Versione dell\'app deprecata',
  'AppVersionDeprecatedMessage': 'La versione della tua app è deprecata. È necessario aggiornare la pagina per ottenere la nuova versione. Fare clic su AGGIORNA per aggiornare la pagina. Se sono presenti dati non salvati, fare clic su Annulla ed eseguire manualmente il backup dei dati, quindi aggiornare la pagina.',
  'Area': 'La zona',
  'AreaCropTitle': 'Superficie per tipo di coltura',
  'Ascending': 'ascendente',
  'Assign': 'Assegnare',
  'AssignExistingFields': 'Assegnare i campi esistenti',
  'AssignedLossAdjusters': 'Periti liquidatori assegnati',
  'AssignedTo': 'Assegnato a',
  'AssociatedEmails': 'E-mail associate',
  'Attachment': 'Allegato',
  'Attachments': 'Allegati',
  'Average': 'Media',
  'AverageFieldArea': 'Superficie media del campo',
  'AvgBollsPerPlant': 'Numero medio di capsule per pianta',
  'AvgEarsPerPlant': 'Numero medio di spighe per pianta',
  'AvgGrainsPerEar': 'Numero medio di chicchi per spiga',
  'AvgGrainsPerPod': 'Numero medio di chicchi per baccello',
  'AvgGrainsPerSilique': 'Numero medio di grani per silique',
  'AvgKernelsPerBoll': 'Numero medio di chicchi per capsula',
  'AvgPodsPerShaft': 'Numero medio di cialde per albero',
  'AvgSiliquesPerShaft': 'Numero medio di silique per albero',
  'BEL': 'Belgio',
  'BRA': 'Brasile',
  'Back': 'Indietro',
  'BackToHome': 'Tornare a casa',
  'Benchmark': 'Segno di riferimento',
  'Biomass': 'Biomassa',
  'BunchWeight': 'Peso medio per grappolo d\'uva in grammi',
  'BunchesPerPlant': 'Numero medio di grappoli d\'uva per pianta',
  'ByCrop': 'Per raccolto',
  'ByField': 'Per campo',
  'ByFieldArea': 'Per area di campo',
  'ByFieldCount': 'Per conteggio dei campi',
  'CAN': 'Canada',
  'CHE': 'Svizzera',
  'CZE': 'Cechia',
  'Calculate': 'Calcolare',
  'CalculateAbbreviated': 'Calcolo',
  'Cancel': 'Annulla',
  'Change': 'Modifica',
  'ChangeOverrideSampleMsg': 'Sei sicuro di voler modificare il valore di sostituzione per questo ritaglio?',
  'ChangeUserAccessRights': 'Modificare i diritti di accesso dell\'utente',
  'CheckForCodepushUpdate': 'Controlla gli aggiornamenti',
  'CirclesArea': 'Area del Cerchio',
  'CirclesDiameter': 'Diametro del cerchio',
  'CirclesRadius': 'Raggio del cerchio',
  'Claim': 'Claim',
  'ClaimNumber': 'Numero di reclamo',
  'ClaimRequests': 'Richieste di reclamo',
  'ClaimStatus_adjuster-accepted': 'Perito accettato',
  'ClaimStatus_assessment-in-progress': 'Valutazione in corso',
  'ClaimStatus_awaiting-adjuster-acceptance': 'In attesa dell\'accettazione del perito',
  'ClaimStatus_completed': 'Completato',
  'ClaimStatus_created-unassigned': 'Creato e non assegnato',
  'ClaimStatus_payment-proposal-sent': 'Proposta di pagamento inviata',
  'ClaimStatus_rejected': 'Respinto',
  'ClaimStatus_visit-date-defined': 'Data della visita definita',
  'ClaimStatus_visit-report-created': 'Rapporto sulle perizie creato',
  'ClaimStatus_visit-samples-collected': 'Iniziata la visita',
  'Claims': 'Affermazioni',
  'Clear': 'Chiaro',
  'ClearAll': 'Cancella tutto',
  'Clearing': 'Cancellazione',
  'ClickHereToAddFarmerEmail': 'Fare clic qui per aggiungere l\'e-mail dell\'agricoltore',
  'ClickUpload': 'Fare clic per caricare',
  'Close': 'Vicino',
  'CloseVisit': 'Visita ravvicinata',
  'Closed': 'Chiuso',
  'ClosedVisits': 'Perizie chiuse',
  'CloudCover': 'Coperto dalle nuvole',
  'CloudCoverInfo': 'Questa colonna mostra i punti salienti se un anno è stato troppo nuvoloso per una misurazione affidabile della produttività (> 50% delle settimane oscurate durante il periodo di punta)',
  'CloudCoverWarning': 'Avviso copertura nuvolosa',
  'CommentInfoWarning': 'Avvertimento! Non appare nei rapporti.',
  'Comments': 'Commenti',
  'CompassHeading': 'Intestazione',
  'CompensableLosses': 'Perdite risarcibili',
  'Condition': 'Condizione',
  'Configuration': 'Configurazione',
  'Confirm': 'Confermare',
  'Contact': 'Contattaci',
  'ContainsCopernicus': 'Contiene informazioni modificate sul servizio Copernicus (2017)',
  'ContinueWithErrorsAndWarnings': 'Continuare senza correggere errori e avvisi?',
  'ConversionRatio': 'Rapporto di conversione',
  'CopyCurrentHarvest': 'Copia il raccolto attuale',
  'CopyHarvestFromPreviousYears': 'Copia il raccolto degli anni precedenti',
  'CopyLink': 'Copia link',
  'CopySample': 'Copia campione',
  'CopyVisit': 'Copia visita',
  'CostType': 'Tipo di costo',
  'Costs': 'Costi ($)',
  'CouldntDelete': 'Impossibile eliminare questo elemento. Devi essere online per eliminare.',
  'CreateNewVisit': 'Crea nuova visita',
  'CropCondition': 'Condizioni del raccolto',
  'CropConditionBreakdown': 'Distribuzione delle condizioni del raccolto',
  'CropCoverage': 'Copertura del raccolto',
  'CropCoverageInfo': 'Mostra la percentuale del campo coperto dalla coltura di interesse',
  'CropType-s': 'Tipi di colture',
  'CropVariety': 'Varietà',
  'CropYieldAverageTitle': 'Rendimento medio stimato',
  'CurHarvest': 'Raccolto attuale',
  'CurrentFilters': 'Filtri attuali',
  'CurrentVisit': 'Visita attuale',
  'CurrentVisits': 'Perizie attuali',
  'Customer': 'Cliente',
  'DEU': 'Germania',
  'DamagedFields': 'Campi danneggiati',
  'Dashboard': 'Pannello di controllo',
  'Date': 'Data',
  'Date-s': 'Data-i',
  'DateRange': 'Intervallo di date',
  'DeadPlantsPct': 'Piante morte (%)',
  'DeclaredHazard': 'Pericolo dichiarato',
  'DeclaredLossCauses': 'Cause di danno dichiarate',
  'Decline': 'Declino',
  'Deductible': 'Franchigia',
  'Deductible-s': 'Franchigia-e',
  'Delete': 'Eliminare',
  'DeleteForEveryone': 'Elimina per tutti',
  'DeleteOverrideSampleMsg': 'Sei sicuro di voler eliminare la stima sostitutiva? In caso affermativo, ricorreremo ai campioni per ricavare il valore stimato aggregato a livello di coltura.',
  'DeleteUser': 'Elimina utente',
  'DeleteUsers': 'Elimina utenti',
  'Delimiter': 'Delimitatore',
  'Descending': 'discendente',
  'DetachedSample-s': 'Campioni staccati',
  'Details': 'Dettagli',
  'DetectDensity': 'Rileva densità',
  'DisablePoiMode': 'Show all farms',
  'Done': 'Fatto',
  'Download': 'Scaricamento',
  'DownloadGeojson': 'Scarica il file GeoJSON',
  'DownloadXLSX': 'Scarica il file Excel',
  'Downloading': 'Download in corso',
  'DownloadingInitialData': 'Download dei dati iniziali',
  'Draw': 'Disegno',
  'DuplicateEntryWasRemoved': 'La seguente voce esiste già e non verrà importata:',
  'ESP': 'Spagna',
  'EarDensity': 'Densità dell\'orecchio',
  'Edit': 'Modificare',
  'EditClaim': 'Modifica reclamo',
  'EditFarm': 'Modifica azienda agricola',
  'EditField': 'Modifica campo',
  'EditFieldShape': 'Modifica forma campo',
  'EditHarvest': 'Modifica Raccolto',
  'EditPolicy': 'Modifica contratto',
  'EditSample': 'Modifica campione',
  'Editors': 'Editori',
  'Email': 'E-mail',
  'EmailMissing': 'Per favore inserisci la tua email!',
  'Emergence': 'Emergenza',
  'EmergenceDate': 'Data di emergenza',
  'EnableNow': 'Abilita ora',
  'EnablePoiMode': 'Mostra solo aziende agricole interessanti',
  'EnablePreciseLocation': 'Si prega di abilitare la posizione precisa',
  'EndDate': 'Data di fine',
  'Entity': 'Entità',
  'ErrMissingFields': 'Si prega di compilare tutti i campi sottolineati sopra.',
  'Error': 'Errore',
  'EstimatedLossAbbr': 'Est. Perdita',
  'EstimatedYield': 'Rendimento stimato',
  'EstimatedYieldAbbr': 'Est. Prodotto',
  'EstimatedYieldAreaWeighted': 'Resa stimata (ponderata per area)',
  'EstimatedYieldArithmeticMean': 'Rendimento stimato (media aritmetica)',
  'EstimatedYieldLoss': 'Perdita di rendimento stimata',
  'Estimation': 'Stima',
  'EventDate': 'Data dell\'evento',
  'EventValidation': 'Convalida degli eventi',
  'Existing': 'Esistente',
  'ExistingClaim': 'Reclamo esistente',
  'ExistingClaimMsg': 'Esiste una rivendicazione esistente per questa azienda agricola. Desideri continuare con il reclamo esistente?',
  'ExistingFarm': 'Questa azienda agricola esiste già.',
  'ExoBinUnsupported': 'Questa funzionalità non è attualmente supportata su questa versione dell\'app. Aggiorna l\'app alla versione più recente.',
  'ExoCamera': 'Fotocamera Exo',
  'ExoDetectionError': 'Impossibile rilevare le orecchie. Riprovare regolando la posizione o acquisendo una nuova immagine.',
  'ExoModelNotDownloaded': 'Modello Exo non scaricato. Connettersi a Internet per scaricare il modello per l\'utilizzo offline.',
  'ExoNotEnabledForUser': 'Questa funzione non è abilitata per la tua azienda o gruppo di utenti. Contatta il tuo amministratore.',
  'ExoTutorialPanDescription': 'Sposta l\'immagine utilizzando un dito.',
  'ExoTutorialPinchDescription': 'Pizzica l\'immagine per ingrandire e rimpicciolire.',
  'ExoTutorialSearchDescription': 'Allineare la zona di rilevamento con la parte desiderata dell\'immagine, finché non diventa verde.',
  'Expert': 'Esperto',
  'Expert-s': 'Esperto(i)',
  'ExpertName': 'Nome dell\'esperto',
  'ExpertRanking': 'Classifica degli esperti',
  'ExpertReport': 'Rapporto sull\'azienda agricola',
  'ExternalClaimId': 'ID attestazione esterna',
  'ExternalFieldId': 'ID campo esterno',
  'ExtremelyHighTemperature': 'Ondata di caldo estremo (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Rischio gelo estremo (-15°C>Tmin)',
  'FRA': 'Francia',
  'FailedToSyncChanges': 'Impossibile sincronizzare le modifiche',
  'Farm': 'Azienda agricola',
  'FarmAddress': 'Indirizzo della azienda agricola',
  'FarmAndHarvest': 'Informazioni sulla azienda agricola e sul raccolto',
  'FarmDeletedPleaseStartOver': 'La azienda agricola è stata eliminata! Per favore, ricomincia da capo',
  'FarmFormComment': 'Ulteriori informazioni operative',
  'FarmHarvestLevel': 'A livello di raccolto',
  'FarmHarvestLevelObservation': 'Osservazione del livello del raccolto',
  'FarmHarvests': 'Raccolti agricoli',
  'FarmInfo': 'Informazioni sull\'azienda agricola',
  'FarmLocationPrompt': 'Premi con forza sulla mappa per impostare la posizione della azienda agricola o premi il pulsante a sinistra per utilizzare la tua posizione',
  'FarmName': 'Nome della azienda agricola',
  'FarmReference': 'Riferimento dell\'azienda agricola',
  'Farmer': 'Contadino',
  'FarmerName': 'Nome dell\'agricoltore',
  'FarmerOrRepresentative': 'Agricoltore o rappresentante',
  'FarmerRepresentative': 'Rappresentante degli agricoltori',
  'FarmersEstimatedYieldLoss': 'Perdita di rendimento stimata dall\'agricoltore',
  'Farms': 'Fattorie',
  'FeasibleYield': 'Rendimento fattibile',
  'FeasibleYieldEmpty': 'Il rendimento fattibile è vuoto.',
  'Feedback': 'Feedback',
  'FetchReportOfflineInfo': 'Sembra che tu sia offline, pertanto non è possibile recuperare il rapporto.',
  'Field': 'Campo',
  'Field-s': 'Campo(i)',
  'FieldArea': 'Area del campo',
  'FieldAreaAndReference': 'Area del campo e riferimento',
  'FieldCultivatedArea': 'Superficie coltivata (campo)',
  'FieldFormComment': 'Ulteriori informazioni sulla trama',
  'FieldHarvests': 'Raccolti sul campo',
  'FieldInfo': 'Informazioni sul campo',
  'FieldLevel': 'A livello di campo',
  'FieldLocationPrompt': 'Premi con forza sulla mappa per impostare la posizione del campo o premi il pulsante a sinistra per utilizzare la tua posizione',
  'FieldManagement': 'Gestione del campo',
  'FieldMissingCurPolicyHarvest': 'A questo campo non sono assegnati un contratto e un raccolto correnti; fare clic qui per assegnarne uno.',
  'FieldReference': 'Riferimento al campo',
  'FieldScoring': 'Punteggio sul campo',
  'FieldShapeAndFarm': 'Forma del campo e azienda agricola',
  'Fields': 'Campi',
  'FileActions': 'Azioni sui file',
  'FilterList': 'Elenco filtri',
  'Filters': 'Filtri',
  'FirstAndLastName': 'Nome e cognome',
  'FirstName': 'Cognome',
  'FloodZone': 'Zona alluvionale',
  'ForgotPassword': 'Reset la password',
  'FormSubmitted': 'Salvato!',
  'FrontPageReportDisclaimer': 'Fatta salva la validità delle garanzie',
  'FungicideApplied': 'Fungicida applicato',
  'GPSModuleUnavailable': 'Modulo GPS non disponibile',
  'Generate': 'Creare',
  'GenerateReport': 'Genera rapporto',
  'GeneratingReportPleaseWait': 'Generazione del rapporto, attendere...',
  'GeographicalArea': 'Area geografica',
  'GetDirectionsField': 'Ottieni indicazioni per il campo',
  'GrainMoisture': 'Umidità del grano',
  'GrainMoistureReduction': 'Riduzione dovuta all\'umidità del grano',
  'GrantsTo': 'Sovvenzioni a',
  'GrapeVariety': 'Varietà di uva',
  'GrossSampleProduction': 'Produzione lorda del campione',
  'GuaranteedLosses': 'Perdite garantite',
  'HUN': 'Ungheria',
  'HailInfo': 'Questo livello mostra il rischio di eventi di grandine.',
  'Harvest': 'Raccolto',
  'HarvestArea': 'Area di ritaglio',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'L\'area di raccolta deve essere positiva e non più grande dell\'area del campo!',
  'HarvestFormComment': 'Ulteriori informazioni sulla raccolta',
  'HarvestInfo': 'Informazioni sulla raccolta',
  'HarvestLoss': 'Perdita del raccolto',
  'HarvestModificationDisabledMsg': 'Questo raccolto non può essere modificato poiché è stato creato dall\'assicuratore.',
  'HarvestPlotId': 'ID della sottotrama',
  'HarvestPractice': 'Pratica di raccolta',
  'HarvestReference': 'Riferimento del raccolto',
  'HarvestYear': 'Anno del raccolto',
  'Harvester': 'Mietitrice',
  'HarvesterDistanceHarvested': 'Raccolto a distanza',
  'HarvesterPlatformWidth': 'Larghezza di raccolta della piattaforma',
  'Harvests': 'Raccolti',
  'HarvestsWillNotBeUpdated': 'I raccolti di questo campo non verranno aggiornati.',
  'HelloReportIssue': 'Salve, vorrei segnalare il seguente problema con l\'app GreenTriangle:',
  'HerbicideApplied': 'Erbicida applicato',
  'HereAddAsNew': 'Clicca qui per aggiungere invece come nuova azienda agricola.',
  'HideDataFromMap': 'Nascondi i dati dalla mappa',
  'HighHailRisk': 'Elevato rischio di danni da grandine',
  'HighPWind': 'Tempesta (28,5-32,5 m/s)',
  'HighPrecipitation': 'Precipitazioni elevate (30-50 mm/settimana)',
  'HighSoilMoisture': 'Leggermente più umido del solito',
  'HighTemperature': 'Ondata di caldo leggero (25°C<Tmax<29°C)',
  'HighVegetation': 'Salute della vegetazione leggermente migliore del solito (+25%)',
  'Higher': 'Più alto',
  'HigherSoilMoisture': 'Più umido del solito',
  'HigherTemperature': 'Ondata di caldo moderato (29°C<Tmax<34°C)',
  'HigherVegetation': 'Salute della vegetazione migliore del solito (+40%)',
  'Highest': 'Più alto',
  'HighestSoilMoisture': 'Molto più umido del solito',
  'HighestTemperature': 'Forte ondata di caldo (34°C<Tmax<38°C)',
  'HighestVegetation': 'Salute della vegetazione molto più elevata del solito (+55% o più)',
  'HistoricalDeclaredYields': 'Rendimenti storici dichiarati',
  'HistoricalObservedYields': 'Rendimenti storici osservati',
  'HistoricalRealizedYields': 'Rendimenti storici realizzati',
  'Homogeneity': 'Omogeneità',
  'HomogeneityInfo': 'Il punteggio di omogeneità misura quanto omogeneo è stato storicamente il campo in termini di sviluppo delle colture',
  'ID': 'ID',
  'ITA': 'Italia',
  'ImportData': 'Importa dati',
  'Impurities': 'Impurità',
  'Info': 'Informazioni',
  'InheritedFrom': 'Ereditato da',
  'Initials': 'Iniziali',
  'InsecticideApplied': 'Insetticida applicato',
  'Insee': 'INSEE',
  'Install': 'Installare',
  'InsuredArea': 'Zona assicurata',
  'InsuredCropRequired': 'Seleziona almeno una coltura assicurata per continuare.',
  'InsuredCrops': 'Colture assicurate',
  'InsuredPrice': 'Prezzo assicurato',
  'InsuredProduction': 'Produzione assicurata',
  'InsuredYield': 'Rendimento assicurato',
  'InterfieldInfo': 'Il "Confronto sul campo" mostra lo stato assoluto della vegetazione e consente il confronto diretto dello stato generale della vegetazione tra diversi appezzamenti.',
  'InteryieldInfo': 'La previsione della resa mostra la resa prevista in base al telerilevamento e ai dati climatici disponibili fino alla data indicata.',
  'IntrafieldInfo': 'La variabilità intra-campo mostra lo stato di salute normalizzato della vegetazione per l\'appezzamento monitorato. L\'appezzamento è suddiviso in 5 zone di uguale area corrispondenti al 20% (o quintile) dei valori dell\'indice, dal più basso al più alto.',
  'InvalidShapeTraced': 'La tua forma non può avere linee che si intersecano. Disegna una forma semplice senza incroci.',
  'Irrigated': 'Irrigato',
  'IrrigatedQuestionMark': 'Irrigato?',
  'KernelsPerEar': 'Noccioli per orecchio',
  'KernerlsPerUnit': 'Kernel per unità',
  'LTU': 'Lituania',
  'LastChanged': 'Ultima modifica',
  'LastName': 'Nome',
  'Legend': 'Legenda',
  'LinkCopiedToClipboard': 'Il collegamento è stato copiato negli appunti',
  'LinkToThisLocation': 'Collegamento a questa posizione',
  'List': 'Elenco',
  'LiveSampleFeed': 'Feed di esempio in tempo reale',
  'Loading': 'Caricamento',
  'Locale': 'Lingua',
  'Location': 'Posizione',
  'LocationAndFarm': 'Posizione e azienda agricola',
  'LocationMocked': 'Sembra che la tua posizione sia derisa. Utilizza la tua posizione reale per procedere.',
  'LocationNotProvided': 'La posizione deve essere abilitata per utilizzare questa funzione.',
  'Login': 'Login',
  'Logout': 'Disconnettersi',
  'LossAdjuster': 'Perito liquidatore',
  'LossCause': 'Causa di perdita',
  'LossCauseRecognized': 'Causa della perdita riconosciuta',
  'LossEstimation': 'Stima delle perdite',
  'LossEventDate': 'Data dell\'evento di perdita',
  'Losses': 'Perdite',
  'LowHailRisk': 'Basso rischio di danni da grandine',
  'LowPrecipitation': 'Precipitazioni scarse (10-15 mm/settimana)',
  'LowSoilMoisture': 'Leggermente più secco del solito',
  'LowTemperature': 'Rischio gelate leggere (-1°C<Tmin<2°C)',
  'LowVegetation': 'Salute della vegetazione leggermente inferiore al solito (-25%)',
  'LowWind': 'Vento forte (17-24,5 m/s)',
  'Lower': 'Inferiore',
  'LowerSoilMoisture': 'Più secco del solito',
  'LowerTemperature': 'Rischio gelo moderato (-5°C<Tmin<-1°C)',
  'LowerVegetation': 'Salute della vegetazione inferiore al solito (-40%)',
  'Lowest': 'Il più basso',
  'LowestSoilMoisture': 'Molto più secco del solito',
  'LowestTemperature': 'Rischio di forti gelate (-15°C<Tmin<-5°C)',
  'LowestVegetation': 'Salute della vegetazione molto più bassa del solito (-55% o meno)',
  'MAR': 'Marocco',
  'MDA': 'Moldavia',
  'MEX': 'Messico',
  'ManageUserAccountAndSettings': 'Gestisci i tuoi account e le tue impostazioni',
  'Manager': 'Manager',
  'Map': 'Carta geografica',
  'MeasureDensity': 'Misurare la densità',
  'Median': 'Mediano',
  'Merge': 'Unisci',
  'Method': 'Metodo',
  'MixedTemperature': 'Zona mista di fluttuazione ad alta temperatura',
  'ModerateHailRisk': 'Rischio moderato di danni da grandine',
  'ModeratePrecipitation': 'Precipitazioni moderate (15-30 mm/settimana)',
  'ModerateWind': 'Burrasca (24,5-28,5 m/s)',
  'Months': 'Mesi',
  'MoreSpecificHarvestDetected': 'Rilevato un raccolto più specifico che sostituirà il raccolto esistente.',
  'MunicipalMedianVegetation': 'Vegetazione Mediana del Comune',
  'Municipality': 'Comune',
  'MustAddCustomer': 'È necessario aggiungere almeno un cliente',
  'MustAddEmail': 'È necessario fornire un\'e-mail valida',
  'MustSelectACustomer': 'È necessario selezionare un cliente per continuare',
  'MustSelectCropAndYear': 'È necessario selezionare un raccolto e un anno',
  'NDVIRange': 'Gamma NDVI',
  'NationalYield': 'Rendimento nazionale',
  'NeedCurrentHarvest': 'È necessario aggiungere un raccolto per questa stagione prima di continuare.',
  'NeedHelpContact': 'Problemi con il tuo accesso? - Contattaci',
  'New': 'Nuovo',
  'NewValue': 'Nuovo valore',
  'NewVersionWarning': 'È disponibile una nuova versione dell\'app.',
  'NewVisit': 'Nuova visita',
  'Next': 'Prossimo',
  'No': 'No',
  'NoClaims': 'Nessuna pretesa',
  'NoData': 'Nessun dato trovato.',
  'NoDataFoundForYourFilters': 'Non disponiamo di dati per i tuoi attuali criteri di filtro.',
  'NoFarmEditors': 'Nessun editor assegnato a questa farm',
  'NoGpsSupport': 'Questo dispositivo non dispone di un\'unità GPS.',
  'NoHailRisk': 'Nessun rischio di danni',
  'NoOfflineFarms': 'Nessuna azienda agricola salvata per l\'utilizzo offline.',
  'NoOngoingVisit': 'Fare clic su (+) qui sopra per iniziare una nuova visita.',
  'NoPrecipitation': 'Nessuna precipitazione (<5 mm/settimana)',
  'NoSampleDateSelectionWarning': 'Non è stata selezionata alcuna data di campionamento. Selezionane almeno uno per evitare di avere un report vuoto.',
  'NoTelepacAvailable': 'Telepac non disponibile',
  'NoVariety': 'Nessuna varietà',
  'NoWind': 'Brezza o assenza di vento (<17 m/s)',
  'NonCompensableLosses': 'Perdite non risarcibili',
  'NonIrrigated': 'Non irrigato',
  'NonOrganic': 'Non organico',
  'NormalSoilMoisture': 'Come di solito',
  'NormalTemperature': 'Nessun rischio gelo/calore (2°C<Tmin; Tmax<25°C)',
  'NormalVegetation': 'Salute normale della vegetazione',
  'NotAffected': 'Non affetto',
  'NotAvailable': 'Non disponibile',
  'NumberMatchingFields': 'Numero di campi corrispondenti',
  'NumberMatchingSamples': 'Numero di campioni corrispondenti',
  'NumberOfFields': 'Numero di campi',
  'NumberOfLossAdjusters': 'Numero di periti liquidatori',
  'NumberOfPolicies': 'Numero di contratti',
  'NumberOfSamples': 'Numero di campioni',
  'Occurence': 'Evento',
  'OfFieldArea': 'di superficie di campo',
  'OfficialHarvestArea': 'Zona di raccolta ufficiale',
  'Offline': 'disconnesso',
  'OfflineAreas': 'Aree offline',
  'OfflineFarms': 'Fattorie offline',
  'Ok': 'OK',
  'Ongoing': 'In corso',
  'OpenCamera': 'Apri Fotocamera',
  'OpenFarm': 'Azienda agricola aperta',
  'OpenPolicy': 'Contratto aperto',
  'OpenVisit': 'Visita aperta',
  'Or': 'O',
  'Organic': 'Biologico',
  'OrganicQuestionMark': 'Biologico?',
  'OtherCompensableLosses': 'Altre perdite risarcibili',
  'OtherFields': 'Altri campi',
  'OverrideCalculation': 'Sostituisci il rendimento aggregato',
  'OverrideEstimatedYield': 'Sostituisci rendimento stimato',
  'Overview': 'Panoramica',
  'PER': 'Perù',
  'POL': 'Polonia',
  'PRY': 'Paraguay',
  'Password': 'Password',
  'PasswordMissing': 'Per favore inserisci la tua password!',
  'PastMonth': 'Mese scorso',
  'PastReportsClickToRefresh': 'Rapporti precedenti: fai clic per aggiornare:',
  'PastWeek': 'Settimana scorsa',
  'PastYear': 'L\'anno scorso',
  'Pause': 'Pausa',
  'PeakVegetationDate': 'Data del picco della vegetazione',
  'PendingDownload': 'Download in sospeso',
  'PendingUpload': 'Caricamento in sospeso',
  'PerCropSignature': 'Firma per raccolto',
  'Phone': 'Telefono',
  'Photo': 'Foto',
  'Photos': 'Fotografie',
  'PhotosAndReferences': 'Foto e riferimenti',
  'PixelResolution': 'Risoluzione pixel',
  'PlanetTiles': 'Immagini Planet',
  'PlanetTilesBoundsWarning': 'Nessun riquadro disponibile per la data e l\'area selezionate. Seleziona una data o un\'area diversa.',
  'PlantDensity': 'Densità delle piante',
  'PlantsCollected': 'Piante raccolte',
  'PleaseAddFields': 'Aggiungi alcuni campi per continuare',
  'PleaseConnect': 'Nessuna connessione Internet rilevata. Per accedere, assicurati di essere connesso a Internet!',
  'PleaseEnableLocation': 'Abilita la posizione precisa per GreenTriangle.',
  'PleaseEnablePhotoPermissions': 'Per continuare, abilita la fotocamera nelle impostazioni',
  'PleaseEnterEmail': 'Inserisci un\'e-mail.',
  'PleaseEnterValue': 'Per favore inserire un valore.',
  'PleaseReviewReportBeforeSigning': 'Si prega di rivedere il rapporto prima di firmare',
  'PleaseSelectClaim': 'Seleziona reclamo',
  'PleaseSelectDate': 'Seleziona una data.',
  'PleaseSelectFarm': 'Seleziona un\'azienda agricola per continuare',
  'PleaseSelectField': 'Seleziona un campo per continuare',
  'PleaseSelectLocation': 'Seleziona una posizione per continuare.',
  'PleaseSelectPolicy': 'Seleziona una policy per continuare',
  'PleaseUpgradeLatest': 'La tua versione di GreenTriangle non è aggiornata. Si prega di aggiornare alla versione più recente',
  'PointOrZone': 'Punto/Zona',
  'Policies': 'Contratti',
  'Policy': 'Contrarre',
  'PolicyFormComment': 'Ulteriori informazioni sulla polizza',
  'PolicyNumber': 'Numero di contratto',
  'Polygon': 'Poligono',
  'Portfolio': 'Portafoglio',
  'PortfolioReport': 'Rapporto sul portafoglio',
  'PotentialDuplicates': 'Le seguenti voci sembrano simili. Valuta la possibilità di selezionare una delle seguenti opzioni',
  'Practice': 'Pratica',
  'PrecipitationInfo': 'Le precipitazioni settimanali totali denotano l\'accumulo di acqua liquida e ghiacciata, comprese pioggia e neve, che cade sulla superficie terrestre entro 7 giorni. Non include la nebbia, la rugiada o le precipitazioni che evaporano nell\'atmosfera prima di raggiungere la superficie della Terra.',
  'Preview': 'Anteprima',
  'PreviewAndSend': 'Anteprima e invio',
  'Previous': 'Precedente',
  'PreviousDate-s': 'Date precedenti',
  'PriceRetainedByExpert': 'Prezzo trattenuto dall\'esperto',
  'Primary': 'Primario',
  'PrimaryYieldLoss': 'Perdita di rendimento primario',
  'PrivacyPolicy': 'polizza sulla riservatezza',
  'Processing': 'in lavorazione',
  'Productivity': 'Produttività',
  'ProductivityInfo': 'Il punteggio di produttività stima quanto produttivo è stato storicamente il campo',
  'ROU': 'Romania',
  'RUS': 'Russia',
  'Recipients': 'Destinatari',
  'RecognizedHazard': 'Pericolo riconosciuto',
  'Reference': 'Riferimento',
  'Refresh': 'ricaricare',
  'Region': 'Regione',
  'RegionLevel': 'Livello regionale',
  'RegionLevel_commune': 'Comune',
  'RegionLevel_country': 'Paese',
  'RegionLevel_state': 'Stato',
  'RegionalStatistic': 'Statistica regionale',
  'Regions': 'Regioni',
  'RelativeScoring': 'Punteggio relativo',
  'RelativeScoringInfo': 'I punteggi relativi riflettono la differenza del punteggio di un campo con il punteggio mediano dei campi di una regione (utilizzando circa 100 campi campione), mentre il punteggio %-ile posiziona il punteggio del campo all\'interno dei campi della regione (5 è il percentile superiore, 1 quello inferiore percentile, 3 la mediana)',
  'Remove': 'Rimuovere',
  'RemoveCrop': 'Rimuovi ritaglio',
  'RemoveFarm': 'Rimuovi azienda agricola',
  'RemoveField': 'Rimuovi campo',
  'RemoveHarvest': 'Rimuovi raccolto',
  'RemovedGroups': 'Gruppi rimossi',
  'RenewHarvests': 'Rinnovare i raccolti',
  'ReportDate': 'Data del rapporto',
  'ReportGenerationPageComment': 'Commento generale alla relazione',
  'ReportNoFields': 'Questa azienda agricola non ha campi su cui creare un rapporto!',
  'Reset': 'Reset',
  'ResetLink': 'Invia un collegamento di reimpostazione',
  'Restore': 'Ristabilire',
  'Resume': 'Riprendere',
  'Risks': 'Rischi',
  'RowDistanceCollected': 'Distanza di fila raccolta',
  'RowsAndWidth': 'Righe e larghezza',
  'SRB': 'Serbia',
  'Sample': 'Campione',
  'Sample-s': 'Campioni',
  'SampleCropTitle': 'Campioni per tipo di coltura',
  'SampleDate': 'Data del campione',
  'SampleFormComment': 'Commento/Osservazione',
  'SampleFormMissingImagesWarning': 'Non hai scattato una foto per questo campione. Aggiungine uno per continuare.',
  'SampleLocation': 'Posizione del campione',
  'SampleLocationInaccurate': 'Posizione imprecisa: attiva la posizione precisa, se non lo hai già fatto. Quindi fare nuovamente clic su "Usa la mia posizione".',
  'SampleLocationOutsideField': 'La posizione del campione non è all\'interno del campo selezionato.',
  'SampleLocationPrompt': 'Seleziona il campo del campione sulla mappa, quindi premi con forza sulla mappa per impostare la posizione del campione o premi il pulsante a sinistra per utilizzare la posizione corrente.',
  'SampleType': 'Tipo di campione',
  'SampledArea': 'Area campionata',
  'Samples': 'Campioni',
  'SamplingDate-s': 'Date di campionamento',
  'Save': 'Salva',
  'SaveOffline': 'Salva offline',
  'Saving': 'Salvataggio',
  'Score': 'Punto',
  'Search': 'Ricerca',
  'SearchAddTipLabel': 'combina i termini di ricerca',
  'SearchExampleDescAddedBy': 'Digita un indirizzo email per mostrare i campioni aggiunti da quell\'utente, nonché i campi e le aziende agricole dei campioni',
  'SearchExampleDescAddress': 'Digita un indirizzo per centrare nuovamente la mappa su quella posizione.',
  'SearchExampleDescCropCondition': 'Digitare una condizione del raccolto per evidenziare i campioni in cui la condizione del raccolto è stata valutata in un certo modo.',
  'SearchExampleDescFarm': 'Trova un\'azienda agricola, i suoi campi e campioni, per nome o indirizzo',
  'SearchExampleDescHarvest': 'Trova tutti gli elementi relativi a un raccolto specifico inserendo il tipo di raccolto, la stagione o l\'anno. Se inserisci un tipo di coltura, i risultati saranno limitati a quest\'anno. Puoi cercare gli anni di raccolto più vecchi in una seconda ricerca.',
  'SearchExampleDescPolicy': 'Trova tutti gli elementi relativi a un numero di contratto',
  'SearchExampleLabelAddedBy': 'trova campioni aggiunti da un indirizzo email',
  'SearchExampleLabelAddress': 'città, indirizzo o paese',
  'SearchExampleLabelCropCondition': 'trovare campioni per una determinata condizione del raccolto (ad esempio "scarso")',
  'SearchExampleLabelFarm': 'ricerca per nome e indirizzo dell\'azienda agricola',
  'SearchExampleLabelHarvest': 'ricerca per raccolto, ad es. "grano"',
  'SearchExampleLabelPolicy': 'ricerca per numero di contratto',
  'SearchExampleLabelUserGroup': 'trovare elementi in portafogli specifici',
  'SearchForClaimNumber': 'Cerca il numero del reclamo',
  'SearchVisit': 'Trova visita non assegnata',
  'Secondary': 'Secondario',
  'SecondaryYieldLoss': 'Perdita di rendimento secondaria',
  'SeedWeightPerHead': 'Peso del seme pro capite',
  'Select': 'Selezionare',
  'SelectAll': 'Seleziona tutto',
  'SelectAppellation': 'Seleziona la denominazione',
  'SelectCostType': 'Seleziona il tipo di costo',
  'SelectCropCondition': 'Seleziona la condizione del raccolto',
  'SelectCropType': 'Seleziona il tipo di raccolto',
  'SelectCurHarvest': 'Seleziona il raccolto attuale',
  'SelectDate': 'Seleziona Data',
  'SelectFarm': 'Seleziona una azienda agricola',
  'SelectFarmsForOfflineAccess': 'Seleziona le aziende agricole per l\'accesso offline',
  'SelectField': 'Seleziona un campo',
  'SelectFieldCurHarvest': 'Seleziona il raccolto attuale del campo',
  'SelectFieldCurPolicy': 'Seleziona il contratto corrente del campo',
  'SelectHarvest': 'Seleziona Raccolto',
  'SelectHarvestYear': 'Seleziona l\'anno del raccolto',
  'SelectLossCause': 'Seleziona la causa della perdita',
  'SelectOneGroup': 'Seleziona gli elementi in un solo portafoglio',
  'SelectPac': 'Seleziona un file valido (.ZIP, .XML, .KML, GeoJSON). Se sei sicuro che questo file sia corretto, contattaci all\'indirizzo support@green-triangle.com.',
  'SelectPolicy': 'Seleziona un contratto',
  'SelectPortfolio': 'Seleziona Portafoglio',
  'SelectReportType': 'Seleziona il tipo di rapporto',
  'SelectSampleType': 'Seleziona il tipo di campione',
  'SelectSubplotId': 'Seleziona ID sottotrama',
  'SelectVariety': 'Seleziona varietà',
  'SelectVegetationStage': 'Seleziona fase fenologica (BBCH)',
  'SelectVisitType': 'Seleziona Tipo di visita',
  'SelectWillOverwriteShape': 'Sei sicuro di voler selezionare questa forma? La forma corrente verrà sovrascritta.',
  'SelectYear': 'Seleziona Anno',
  'SelectedAppellation': 'Denominazione selezionata',
  'SelectedCrops': 'Colture selezionate',
  'SelectedField': 'Campo selezionato',
  'Send': 'Inviare',
  'SendFeedback': 'Invia feedback',
  'SendTo': 'Inviare a',
  'Sending': 'Invio',
  'Sent': 'Inviato',
  'Settings': 'Impostazioni',
  'SevereHailRisk': 'Rischio di danni gravi da grandine',
  'SeverePrecipitation': 'Precipitazioni intense (>75 mm/settimana)',
  'SevereWind': 'Uragano (>32,5 m/s)',
  'ShapeTracerAddPoint': 'Premi sulla mappa per aggiungere un punto oppure premi su un punto per modificarne la posizione o per eliminarlo.',
  'ShapeTracerEditPoint': 'Sposta il punto tramite la mano bianca o premi in qualsiasi altro punto per tornare all\'aggiunta di punti.',
  'Share': 'Condividere',
  'ShowAll': 'Mostra tutto',
  'ShowDataOnMap': 'Mostra i dati sulla mappa',
  'ShowLastAdded': 'Mostra ultimo aggiunto',
  'ShowOnMap': 'Mostra sulla mappa',
  'ShowSamplesAddedIn': 'Mostra campioni aggiunti negli ultimi:',
  'SignAndClose': 'Firma e chiudi',
  'SignatoryName': 'Nome del firmatario',
  'SignatoryNames': 'Nomi dei firmatari',
  'Signature': 'Firma',
  'Signatures': 'Firme',
  'SnowIce': 'Neve/ghiaccio',
  'SoilMoistureInfo': 'L\'indice di anomalia dell\'umidità del suolo quantifica la condizione di umidità nel suolo a una profondità di 40 cm per un periodo di 10 giorni rispetto alla media a lungo termine (10 anni) nello stesso periodo.',
  'SomethingWentWrongError': 'Qualcosa è andato storto.',
  'SowingDate': 'Data di semina',
  'SowingDensity': 'Densità di semina',
  'SpaceBetweenPlants': 'Spazio tra le piante',
  'SpaceBetweenRows': 'Spazio tra le righe',
  'SquareArea': 'Area del quadrato',
  'SquareMeterCut': 'Taglio al metro quadrato / Densità della pianta',
  'SquareSide': 'Lato del quadrato',
  'SquareSize': 'Dimensione del quadrato',
  'Stability': 'Stabilità',
  'StabilityInfo': 'Il punteggio di stabilità misura quanto è stata stabile la produttività dedotta di un campo nel tempo',
  'StartDate': 'Data d\'inizio',
  'StartingYield': 'Rendimento fattibile',
  'Status': 'Stato',
  'SubjectToValidationOfReseedingByExpert': 'Soggetto a validazione della risemina da parte dell\'esperto',
  'Subplots': 'Sottotrame',
  'Summary': 'Riepilogo',
  'SupportedSurface': 'Superficie supportata',
  'SureAddSampleWithoutField': 'Sei sicuro di voler aggiungere un campione senza campo?',
  'SureCancel': 'Sei sicuro di voler annullare prima di salvare i tuoi dati?',
  'SureCloseVisit': 'Sei sicuro di voler chiudere la visita? Il rapporto non è stato firmato.',
  'SureCloseVisitPartial': 'Sei sicuro di voler chiudere la visita? Il rapporto è stato firmato solo parzialmente.',
  'SureDeleteFarm': 'Sei sicuro di voler eliminare questa azienda agricola?',
  'SureDeleteField': 'Sei sicuro di voler eliminare questo campo?',
  'SureDeleteHarvest': 'Sei sicuro di voler eliminare questo raccolto?',
  'SureDeleteSample': 'Sei sicuro di voler eliminare questo campione?',
  'SureDeleteVisit': 'Sei sicuro di voler eliminare questa visita?',
  'SureReset': 'Sei sicuro di voler reimpostare? Perderai tutte le modifiche offline.',
  'SureSelectObject': 'Sei sicuro di voler selezionare invece quanto segue:',
  'SureSignOut': 'Sei sicuro di voler uscire? Perderai tutte le modifiche offline.',
  'SurfacePAC': 'PAC di superficie',
  'SurfaceTemperatureInfo': 'Questo strato mostra le temperature minime e massime settimanali della superficie terrestre che indicano il rischio di gelo e ondate di caldo.',
  'Syncing': 'Sincronizzazione',
  'TableNoRowsFound': 'Nessuna riga trovata',
  'TableOf': 'di',
  'TablePage': 'Pagina',
  'TableRows': 'righe',
  'TelepacAvailableFor': 'Telepac caricato per',
  'TelepacNr': 'CUAA',
  'TelepacReport': 'Importazione PAC',
  'TemperatureInfo': 'Questo livello mostra le temperature dell\'aria minime e massime settimanali di 2 metri che indicano il rischio di gelo e ondate di caldo.',
  'Tertiary': 'Terziario',
  'TertiaryYieldLoss': 'Perdita di rendimento terziario',
  'ThousandKernelWeightGrams': 'Peso 1000 granelli (g)',
  'TopNCropsByHarvestArea': 'Raccolti principali per area di raccolta',
  'Total': 'Totale',
  'TotalArea': 'Area totale',
  'TotalCultivatedArea': 'Superficie coltivata (totale)',
  'TotalEarsDetected': 'Orecchie totali rilevate',
  'TotalHarvestArea': 'Superficie totale del raccolto',
  'TotalLoss': 'Perdita totale',
  'TotalYieldPctLoss': 'Perdita di rendimento totale (%)',
  'UKR': 'Ucraina',
  'URY': 'Uruguay',
  'USA': 'Stati Uniti',
  'UnableToUpload': 'Impossibile caricare',
  'UnassignMyself': 'Annulla l\'assegnazione',
  'Uncategorized': 'Non categorizzato',
  'Undo': 'Disfare',
  'UnguaranteedLosses': 'Perdite non garantite',
  'Unit': 'Unità',
  'UnitsPerPlant': 'Unità per pianta',
  'UnknownErrorOccurred': 'Si è verificato un errore sconosciuto',
  'UpcomingInspection': 'Prossima ispezione',
  'Update': 'Aggiornamento',
  'UpdateMemberships': 'Aggiorna gli abbonamenti',
  'UploadPACFile': 'Carica file PAC',
  'Usage': 'Utilizzo',
  'UseMyLocation': 'Usa la mia posizione',
  'User': 'Utente',
  'UserAdminNoAccess': 'Non vi è alcuna sovrapposizione tra i clienti degli utenti selezionati e i tuoi diritti di amministratore utente.',
  'UserAdminNoCommon': 'Gli utenti selezionati non hanno un\'azienda comune.',
  'UserAdministration': 'Amministrazione utenti',
  'UserGroup': 'Portafoglio',
  'Validation': 'Validazione',
  'VegetationInfo': 'L\'anomalia della salute della vegetazione fornisce una misura alternativa della salute relativa della vegetazione rispetto agli anni precedenti (dal 2001). Questo indicatore può essere utilizzato per monitorare le aree in cui la vegetazione può essere stressata, come indicatore per rilevare la potenziale siccità.',
  'VegetationStage': 'Fase della vegetazione',
  'VeryHighPrecipitation': 'Precipitazioni molto elevate (50-75 mm/settimana)',
  'VeryLowPrecipitation': 'Precipitazioni molto basse (5-10 mm/settimana)',
  'ViewThisInstead': 'Desideri uscire da questo modulo e aprire invece quanto segue:',
  'VinificationRatio': 'Rapporto di vinificazione (kg/hL)',
  'Visit': 'Visita',
  'VisitAssign': 'Assegna visita',
  'VisitCandidateStatus_accepted': 'Accettato',
  'VisitCandidateStatus_assigned': 'Assegnato',
  'VisitCandidateStatus_rejected': 'Respinto',
  'VisitList': 'Le mie perizie',
  'VisitMode': 'Modalità Visita',
  'VisitReport': 'Visita Rapporto',
  'VisitReportEmailInfoMsg': 'Il resoconto della visita firmato verrà inviato agli indirizzi email selezionati.',
  'VisitType': 'Visita Tipo',
  'VisitValidation': 'Visita Convalida',
  'Visited': 'Visitato',
  'Visits': 'Perizie',
  'Volatility': 'Volatilità',
  'WebApp': 'Applicazione Web',
  'WeightPerPlant': 'Peso medio per pianta',
  'WeightPerUnit': 'Peso per unità',
  'WeightedAverage': 'Media ponderata',
  'WindInfo': 'Lo strato massimo di raffiche di vento settimanali rappresenta la massima raffica di vento registrata negli ultimi 7 giorni ad un\'altezza di dieci metri sopra la superficie della Terra.',
  'Withdrawal': 'Ritiro',
  'WouldLikeToRestore': 'Abbiamo trovato dati per questo modulo che non sono stati salvati a causa di un arresto anomalo o di un aggiornamento. Desideri recuperare questi dati?',
  'Year': 'Anno',
  'YearEg': 'Anno (es. 2019)',
  'Yes': 'Sì',
  'YieldAndLoss': 'Rendimento e perdita',
  'YieldCalcUnsupportedCrop': 'Il calcolatore non è supportato per questo tipo di coltura. Attualmente è possibile solo assegnare la densità delle piante.',
  'YieldEstimationMethod': 'Metodo di stima del rendimento',
  'YieldLoss': 'Perdita di rendimento',
  'YieldLossInputHidden': 'L\'immissione della perdita di rendimento è disabilitata perché la causa della perdita non è riconosciuta.',
  'YieldRatio': 'Rapporto di rendimento',
  'YieldTooLarge': 'Valore di rendimento troppo grande',
  'YouMayTryAgain': 'Puoi riprovare',
  'ZAF': 'Sud Africa',
  'ZoomInViewLayer': 'Per vedere questo livello, ingrandisci.',
  'ZoomOutViewLayer': 'Per vedere questo livello, rimpicciolisci.',
  'additional-costs': 'Costi aggiuntivi ($)',
  'agroforestry': 'Agroforestazione',
  'alfalfa': 'Erba medica',
  'allSynced': 'Tutto è stato sincronizzato con il database',
  'almond': 'Mandorla',
  'ananas': 'Ananas',
  'angelica': 'Angelica',
  'anise': 'anice',
  'apples': 'Mele',
  'apples-cider': 'Mele (sidro)',
  'apples-pre-prod': 'Mele (5 anni o meno)',
  'apricots': 'Albicocche',
  'apricots-pre-prod': 'Albicocche (5 anni o meno)',
  'aromatic-plants': 'Piante aromatiche',
  'artichoke': 'Carciofo',
  'asparagus': 'Asparago',
  'avocado': 'Avocado',
  'bananas': 'Banane',
  'barley': 'Orzo',
  'barley-6-row': 'Orzo a 6 file',
  'barley-malting': 'Orzo (malto)',
  'barley-seeds': 'Orzo (semi)',
  'base': 'Base',
  'basic': 'Di base',
  'basil': 'Basilico',
  'bbch-00-grapes': 'A. Germoglio invernale',
  'bbch-05-grapes': 'B. Germoglio lanoso',
  'bbch-09-grapes': 'C. Germogliamento (punta verde)',
  'bbch-11-grapes': 'D. Emergenza delle foglie',
  'bbch-13-grapes': 'E. Foglie separate',
  'bbch-53-grapes': 'F. Infiorescenze visibili',
  'bbch-55-grapes': 'G. Infiorescenze separate',
  'bbch-57-grapes': 'H. Fiori separati',
  'bbch-64-grapes': 'I. Fiorisco',
  'bbch-71-grapes': 'J. Allegagione',
  'bbch-75-grapes': 'K. Bacche dimensione pisello',
  'bbch-77-grapes': 'L. Bacche che si toccano',
  'bbch-81-grapes': 'M. Veraison',
  'bbch-89-grapes': 'N. Maturità',
  'bbch-93-grapes': 'O. Maturazione della canna/Caduta delle foglie',
  'beans': 'Fagioli',
  'beans-dry': 'Fagioli secchi',
  'beans-green': 'Fagioli verdi',
  'beans-red': 'fagioli rossi',
  'beans-seeds': 'Fagioli (semi)',
  'beans-white': 'fagioli bianchi',
  'beetroot': 'Barbabietola',
  'benchmark-yield': 'Rendimento di riferimento',
  'black-medick': 'Medico nero',
  'blackberry': 'Mora',
  'blackcurrant': 'Ribes nero',
  'blueberry': 'Mirtillo',
  'broccoli': 'Broccoli',
  'bromes': 'Bromes',
  'buckwheat': 'Grano saraceno',
  'burdock': 'Bardana',
  'cabbages': 'Cavoli',
  'cabbages-feed': 'Cavoli (mangime)',
  'cabbages-red': 'cavolo rosso',
  'camelina': 'Camelina',
  'caraway': 'Cumino',
  'cardoon': 'Cardo',
  'carob': 'Carruba',
  'carrots': 'Carote',
  'carrots-feed': 'Carote (mangime)',
  'carrots-young': 'Carote',
  'cassava': 'Manioca',
  'castor-bean': 'Fagiolo di ricino',
  'castration-costs': 'Costi di castrazione ($)',
  'cauliflowers': 'Cavolfiori',
  'cedrate': 'Cedro',
  'celeri-leaves': 'Sedano (foglie)',
  'celeri-root': 'Sedano (radice)',
  'celery': 'Sedano',
  'cereals': 'Cereali',
  'chamomile': 'Camomilla',
  'chard': 'Bietola',
  'cherries': 'Ciliegie',
  'chervil': 'Cerfoglio',
  'chestnut': 'Castagna',
  'chia': 'Chia',
  'chickpeas': 'Ceci',
  'chicory': 'Cicoria',
  'chilli': 'Peperoncino',
  'chives': 'Erba cipollina',
  'citrus': 'Agrumi',
  'claim-all': 'Chiusura definitiva della missione',
  'claim-delivery': 'Chiusura definitiva dei risultati della vendemmia',
  'claim-some': 'Chiusura definitiva della cultura',
  'claims-visit': 'Visita finale per il reclamo',
  'claims-visit-file': 'stima',
  'clementines': 'Clementine',
  'clover': 'Trifoglio',
  'cocoa': 'Cacao',
  'coffea': 'Caffè',
  'cold': 'Freddo',
  'compensation-costs': 'Costi di compensazione ($)',
  'connected': 'L\'app è connessa a Internet!',
  'coriander': 'Coriandolo',
  'corn': 'Mais',
  'corn-grain': 'Mais (grano)',
  'corn-popcorn': 'Mais (popcorn)',
  'corn-seeds': 'Mais (semi)',
  'corn-seeds-fertile': 'Mais (semi, fertili)',
  'corn-seeds-sterile': 'Mais (semi, sterili)',
  'corn-silage': 'Mais (insilato)',
  'corn-sweet': 'Mais dolce',
  'corn-sweet-seeds-fertile': 'Mais dolce (semi, fertile)',
  'corn-sweet-seeds-sterile': 'Mais dolce (semi, sterili)',
  'corn-waxy': 'Mais (ceroso)',
  'cornsalad': 'Insalata di mais',
  'cotton': 'cotone',
  'cover-crop': 'Ritaglio di copertura',
  'cranberry': 'Mirtillo',
  'cress': 'Crescione',
  'crop-mon': 'Monitoraggio delle colture',
  'crop_condition': 'Condizione del raccolto',
  'crosne': 'Crosne',
  'cucumbers': 'Cetrioli',
  'cumin': 'Cumino',
  'cynara': 'Cynara',
  'dactylis': 'Dattili',
  'damson': 'Damson',
  'decree': 'Decreto ufficiale',
  'development': '4. Sviluppo di parti di piante raccogliebili',
  'dill': 'Aneto',
  'disease': 'Malattia',
  'downpour': 'Acquazzone',
  'drought': 'Siccità',
  'eggplant': 'Melanzana',
  'elder': 'Sambuco',
  'endives': 'Indivia',
  'excess-water': 'Acqua in eccesso',
  'excessive-air-humidity': 'Umidità eccessiva (aria)',
  'expected-loss': 'Perdita attesa',
  'fallow-land-perennial': 'Terreno incolto (perenne)',
  'fallow-land-spring': 'Terreni incolti (primavera)',
  'false': 'No',
  'fennel': 'Finocchio',
  'fenugreek': 'Fieno greco',
  'festuca': 'Festuca',
  'field-beans': 'Fave',
  'field-beans-seeds': 'Fave (semi)',
  'field-crops': 'Campi coltivati',
  'field-mustard': 'Senape di campo',
  'figs': 'Fichi',
  'fire': 'Fuoco',
  'first-crop': 'Primo raccolto',
  'flageolet-bean': 'Fagioli flageolet',
  'flood': 'Alluvione',
  'flood-risk': 'Rischio alluvioni',
  'flowering': '6. Fioritura',
  'fodder-legumes': 'Legumi foraggere',
  'forest': 'foresta',
  'foxtail-millet': 'Miglio di coda di volpe',
  'frost': 'Gelo',
  'fruit-development': '7. Sviluppo del frutto',
  'full': 'Accesso completo',
  'garlic': 'Aglio',
  'gentiana': 'Genziana',
  'germination': '0. Germinazione/Sviluppo delle gemme',
  'gherkins': 'Cetriolini',
  'ginger': 'Zenzero',
  'go': 'Andare!',
  'good': 'Bene',
  'gooseberries': 'Uva spina',
  'gourd': 'Zucca',
  'grapefruit': 'Pompelmo',
  'grapes': 'Uva',
  'grapes-food': 'Uva (cibo)',
  'grapes-juice': 'Succo d\'uva',
  'grapes-must': 'Mosto d\'uva',
  'grapes-nursery': 'Vivaio della vite',
  'grapes-table': 'Uva da tavola',
  'grassland': 'Prateria',
  'grassland-perennial': 'Prato (perenne)',
  'green-peas': 'Piselli verdi',
  'group-allocator': 'Assegnatore di gruppo',
  'hail': 'Grandine',
  'harvest': 'Raccolto',
  'harvest_crop': 'Tipo di raccolto',
  'harvested': 'Raccolto',
  'harvesting-costs': 'Costi di raccolta ($)',
  'hazelnuts': 'Nocciole',
  'heat-wave': 'Vento caldo e ondata di calore',
  'heatstroke': 'Sbalzo termico',
  'heavy-rainfall': 'Eccesso di pioggia',
  'hemp': 'Canapa',
  'hemp-paper': 'Canapa (carta)',
  'hemp-seeds': 'Semi di canapa)',
  'high-res-mapbox': 'Alta risoluzione',
  'historical-yield': 'Rapporto di rendimento storico',
  'hop': 'Salto',
  'hyssop': 'Issopo',
  'inflorescence': '5. Emergenza/intestazione delle infiorescenze',
  'interfield': 'Confronto sul campo',
  'interfield-a': 'Nessuna vegetazione',
  'interfield-b': 'Vegetazione rada',
  'interfield-c': 'Vegetazione bassa',
  'interfield-d': 'Vegetazione moderata',
  'interfield-e': 'Vegetazione da moderata a densa',
  'interfield-f': 'Vegetazione fitta',
  'interfield-g': 'Elevata attività della vegetazione',
  'interfield-h': 'Attività vegetativa molto elevata',
  'interfield-i': 'Massima attività della vegetazione',
  'interyield': 'Previsione del rendimento',
  'interyield-a': 'Resa estremamente bassa',
  'interyield-b': 'Resa molto bassa',
  'interyield-c': 'Resa bassa',
  'interyield-d': 'Resa da bassa a moderata',
  'interyield-e': 'Resa moderata',
  'interyield-f': 'Resa da moderata ad alta',
  'interyield-g': 'Ad alto rendimento',
  'interyield-h': 'Rendimento molto elevato',
  'interyield-i': 'Rendimento massimo',
  'intrafield': 'Variabilità intra-campo',
  'itemsToUpload': 'Ci sono elementi da caricare nel database.',
  'jerusalem-artichoke': 'Carciofo Di Gerusalemme',
  'kale': 'cavolo',
  'kiwi': 'Actinidia',
  'lack-of-sunlight': 'Mancanza di luce solare',
  'lavandin': 'Lavandino',
  'lavender': 'Lavanda',
  'leaf-development': '1. Sviluppo delle foglie',
  'leek': 'Porro',
  'lemon-balm': 'Melissa',
  'lemons': 'Limoni',
  'lentils': 'Lenticchie',
  'lentils-berry': 'Lenticchie verdi ai frutti di bosco',
  'lentils-puy': 'Lenticchie verdi Puy',
  'lettuce': 'Lattuga',
  'linden': 'Tiglio',
  'lineseed-textile-seeds': 'Tessuti di lino (semi)',
  'linseed': 'Semi di lino',
  'linseed-oilseed': 'Semi di lino (semi oleosi)',
  'linseed-textile': 'Tessile di lino',
  'linseed-textile-fiber': 'Semi di lino (tessile/fibra)',
  'linseed-textile-seeds': 'Semi di lino (semi)',
  'linseed-textile-straw': 'Semi di lino (tessile/paglia)',
  'lotus': 'Loto',
  'lupin': 'Lupino',
  'lupin-sweet': 'Dolce lupino',
  'mandarins': 'Mandarini',
  'mangos': 'Manghi',
  'meadow-grass': 'Erba dei prati',
  'medicinal-plants': 'Piante medicinali',
  'melilotus': 'Meliloto',
  'melons': 'Meloni',
  'membership_type': 'Tipo di abbonamento',
  'meslin': 'Meslin',
  'millet': 'Miglio',
  'mint': 'menta',
  'mirabelles': 'Mirabelle',
  'miscanthus': 'Erba argentata',
  'mixed-cereal': 'Cereali misti',
  'monitoring-visit': 'Visita di monitoraggio',
  'monitoring-visit-file': 'monitoraggio',
  'monitoring-with-review': 'Provisioning/monitoraggio (con gestione, senza provisioning)',
  'monitoring-with-review-provisioning': 'Provisioning/monitoraggio (con gestione, con provisioning)',
  'monitoring-without-review': 'Provisioning/monitoraggio (senza gestione, senza provisioning)',
  'monitoring-without-review-provisioning': 'Provisioning/monitoraggio (senza gestione, con provisioning)',
  'month_0': 'Gen',
  'month_1': 'Febbraio',
  'month_10': 'Novembre',
  'month_11': 'Dicembre',
  'month_2': 'Mar',
  'month_3': 'Aprile',
  'month_4': 'Maggio',
  'month_5': 'Giugno',
  'month_6': 'Luglio',
  'month_7': 'Agosto',
  'month_8': 'Settembre',
  'month_9': 'Ottobre',
  'most-recent': 'Piu recente',
  'mustard': 'Mostarda',
  'nashi-pear': 'Pera Nashi',
  'nectarines': 'Nettarine',
  'noOfflineAreas': 'Per utilizzare GreenTriangle offline, è meglio scaricare le mappe sul tuo dispositivo. Premi Sì per farlo adesso.',
  'none': 'Nessuno',
  'normal': 'Normale',
  'notConnected': 'L\'app non è connessa a Internet.',
  'not_available': 'Non disponibile',
  'not_planted': 'Non piantato',
  'nursery': 'Vivai',
  'oats': 'Avena',
  'oats-seeds': 'Avena (semi)',
  'oilseeds': 'Semi oleosi',
  'olives': 'Olive',
  'onions': 'Cipolle',
  'oranges': 'Arance',
  'orchard': 'Frutteto (più di 5 anni)',
  'orchard-pre-prod': 'Frutteto (5 anni o meno)',
  'orchards': 'Frutteti',
  'oregano': 'Origano',
  'other-aromatic-medicinal': 'Altre piante aromatiche o medicinali',
  'other-berries': 'Altre bacche',
  'other-cereals': 'Altri cereali',
  'other-feed': 'Altre colture foraggere',
  'other-fruits': 'Altri frutti',
  'other-industry': 'Altre colture industriali',
  'other-legumes': 'Altri legumi',
  'other-loss': 'Altro (perdita)',
  'other-oilseed': 'Altri prodotti oleosi',
  'other-textile': 'Altri tessili',
  'other-vegetables': 'Altre verdure',
  'parnsip': 'Parnsip',
  'parsley': 'Prezzemolo',
  'peach-blood': 'Pesca sanguigna',
  'peach-flat': 'Pesca piatta',
  'peaches': 'Pesche',
  'peaches-pre-prod': 'Pesche (5 anni o meno)',
  'peanuts': 'Arachidi',
  'pears': 'Pere',
  'pears-pre-prod': 'Pere (5 anni o meno)',
  'peas': 'Piselli',
  'peas-feed': 'Piselli (mangime)',
  'peas-seeds': 'Piselli (semi)',
  'peas-split': 'Piselli spezzati',
  'pepper': 'Peperone',
  'perennial': 'Perenne',
  'perennial-crops': 'Colture perenni',
  'persimmon': 'Cachi',
  'pest': 'Parassita',
  'pickle': 'Cetriolo sottaceto',
  'pineapples': 'Ananas',
  'pistachio': 'Pistacchio',
  'pleaseCheckForm': 'Controlla i campi del modulo e riprova.',
  'plum': 'Susina',
  'polyculture': 'Policultura',
  'poor': 'Povero',
  'poppy': 'Papavero',
  'potatoes': 'Patate',
  'potatoes-industry': 'Patate (industria)',
  'potatoes-seeds': 'Patate (semi)',
  'potatoes-starch': 'Patate (amido)',
  'potatoes-ware': 'Patate da consumo',
  'precipitation': 'Precipitazioni totali',
  'predicted-yield': 'Rapporto di rendimento previsto',
  'preinspection-none': 'Valutazione del rischio',
  'preinspection-visit': 'Pre-ispezione',
  'preinspection-visit-file': 'preispezione',
  'production-costs': 'Costi di produzione ($)',
  'pulses': 'Impulsi',
  'pumpkin': 'Zucca',
  'purification-costs': 'Costi di purificazione ($)',
  'quinces': 'Mele cotogne',
  'quinoa': 'Quinoa',
  'radishes': 'Ravanelli',
  'rapeseed': 'Colza',
  'rapeseed-feed': 'Colza (mangime)',
  'rapeseed-seeds': 'Colza (semi)',
  'raspberry': 'Lampone',
  'ray-grass': 'Erba di razza',
  'redcurrant': 'Ribes rosso',
  'remaining-crops': 'Colture rimanenti',
  'replanting': 'Reimpianto',
  'resowing-costs': 'Costi di risemina ($)',
  'rhubarb': 'Rabarbaro',
  'rice': 'Riso',
  'ripening': '8. Maturazione o maturità del frutto e del seme',
  'rocket': 'Racola',
  'root-crops': 'Radici commestibili',
  'rosemary': 'Rosmarino',
  'rutabaga': 'Rutabaga',
  'rye': 'Segale',
  'rye-seeds': 'Ibridi di semi di segale',
  'safflower': 'Cartamo',
  'sage': 'Salvia',
  'sainfoin': 'Lupinella',
  'salad': 'Insalata',
  'salsify': 'Salsefrica',
  'salvia': 'Salvia',
  'sandstorm': 'Tempesta di sabbia',
  'satellite': 'Satellitare',
  'satureja': 'Satureja',
  'second-crop': 'Secondo raccolto',
  'senescence': '9. Senescenza, inizio della dormienza',
  'shallots': 'Scalogno',
  'side-shoot': '2. Formazione di germogli laterali/accestimento',
  'snow': 'Eccesso di neve',
  'snow-peas': 'Taccole',
  'soil-moisture': 'Anomalia dell\'umidità del suolo',
  'sorghum': 'Sorgo (grano)',
  'sorghum-feed': 'Sorgo (mangime)',
  'sorghum-seeds': 'Sorgo (semi)',
  'sorghum-silage': 'Sorgo (insilato)',
  'sorrel': 'Acetosa',
  'soybeans': 'Semi di soia',
  'soybeans-seeds': 'Soia (semi)',
  'spelt': 'Farro',
  'spinach': 'Spinaci',
  'spring': 'Primavera',
  'sprouts': 'Cavoletti di Bruxelles',
  'squash': 'Schiacciare',
  'squash-max': 'Zucca (Cucurbita maxima)',
  'stevia': 'Stevia',
  'storm': 'Vento',
  'strawberry': 'Fragola',
  'sugar-beet': 'Barbabietola',
  'sugar-beet-feed': 'Barbabietola da zucchero (mangime)',
  'sugar-beet-seeds': 'Barbabietola da zucchero (semi)',
  'sugar-cane': 'Canna da zucchero',
  'sugar-cane-feed': 'Canna da zucchero (mangime)',
  'sunburn': 'Colpo di sole',
  'sunflower': 'Girasole',
  'sunflower-oilseed': 'Girasole (semi oleosi)',
  'sunflower-seeds': 'Semi di girasole)',
  'surface-temperature': 'Rischio di gelo e calore (superficie)',
  'switchgrass': 'Switchgrass',
  'syncDataDb': 'Sincronizzazione dei dati nel database.',
  'tarragon': 'Dragoncello',
  'temperature': 'Rischio di gelo e calore (aria)',
  'thistle': 'Cardo',
  'thyme': 'Timo',
  'timothy-grass': 'Timoteo-erba',
  'tobacco': 'Tabacco',
  'tobacco-blond': 'Tabacco biondo',
  'tobacco-brown': 'Tabacco bruno',
  'tobacco-burley': 'Tabacco Burley',
  'tobacco-virginie': 'Tabacco Virginia',
  'tomatoes': 'Pomodori',
  'tomatoes-processing': 'Pomodori (industria)',
  'tons': 't',
  'tornado': 'Tornado',
  'triticale': 'Triticale',
  'triticale-seeds': 'Triticale (semi)',
  'true': 'Si',
  'turmeric': 'Curcuma',
  'turnip': 'Rapa',
  'turnip-feed': 'Rapa (mangime)',
  'unit': 'unità',
  'unknown': 'Sconosciuto',
  'urtica': 'Ortiche',
  'user-admin': 'Gestore utenti',
  'valerian': 'Valeriana',
  'validation-visit': 'Visita preliminare al sinistro',
  'validation-visit-file': 'occorrenza',
  'validation-with-review': 'Evento con gestione',
  'validation-without-review': 'Evento senza gestione',
  'vanilla': 'Vaniglia',
  'vegetables': 'Verdure',
  'vegetation': 'Anomalia della vegetazione',
  'vegetative-growth': '3. Allungamento dello stelo/crescita della rosetta',
  'verbena': 'Verbena',
  'very_good': 'Molto bene',
  'very_poor': 'Molto povero',
  'vetch': 'Veccia',
  'vin-de-table': 'Vino da tavola',
  'walnuts': 'Noci',
  'watermelon': 'Anguria',
  'weeds': 'Erbacce',
  'wheat': 'Grano (Tenero)',
  'wheat-einkorn': 'Grano monococco',
  'wheat-feed': 'Grano (mangime)',
  'wheat-hard': 'Grano (duro)',
  'wheat-hard-seeds': 'Grano duro (semi)',
  'wheat-seeds': 'Grano (semi)',
  'wheat-top': 'Grano (Tenere - macinazione migliorata)',
  'wild-animals': 'Animali selvaggi',
  'wind': 'Vento',
  'winter': 'Inverno',
  'yams': 'Ignami',
  'zucchini': 'Zucchine',
  '-': '-',
  'UnknownLoss': 'Sconosciuto (Causa di perdita)',
  'acres': 'ac',
  'added_by': 'Aggiunto da',
  'added_on': 'Aggiunto a',
  'arrobas-bra': '@/ha',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'bales/ac',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'corn-grain-white': 'Mais (grano) (white)',
  'corn-grain-yellow': 'Mais (grano) (yellow)',
  'corn-silage-white': 'Mais (insilato) (white)',
  'corn-silage-yellow': 'Mais (insilato) (yellow)',
  'crop_id': 'Tipo di raccolto',
  'custom-a': 'Vegetazione Mediana del Comune',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Sviluppo di parti di piante raccogliebili',
  'development-sorghum': '4. Sviluppo di parti di piante raccogliebili',
  'development-sugar-beet': '4. Sviluppo di parti di piante raccogliebili',
  'eur': '€',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'farm_id': 'Azienda agricola',
  'feet': 'ft',
  'flowering-cereals': '6. Fioritura',
  'flowering-corn': '6. Fioritura',
  'flowering-fruits': '6. Fioritura',
  'flowering-grapes': '6. Fioritura',
  'flowering-legumes': '6. Fioritura',
  'flowering-rapeseed': '6. Fioritura',
  'flowering-sorghum': '6. Fioritura',
  'flowering-soybeans': '6. Fioritura',
  'flowering-sunflower': '6. Fioritura',
  'fruit-development-cereals': '7. Sviluppo del frutto',
  'fruit-development-corn': '7. Sviluppo del frutto',
  'fruit-development-fruits': '7. Sviluppo del frutto',
  'fruit-development-grapes': '7. Sviluppo del frutto',
  'fruit-development-legumes': '7. Sviluppo del frutto',
  'fruit-development-rapeseed': '7. Sviluppo del frutto',
  'fruit-development-sorghum': '7. Sviluppo del frutto',
  'fruit-development-soybeans': '7. Sviluppo del frutto',
  'fruit-development-sunflower': '7. Sviluppo del frutto',
  'germination-cereals': '0. Germinazione/Sviluppo delle gemme',
  'germination-corn': '0. Germinazione/Sviluppo delle gemme',
  'germination-fruits': '0. Germinazione/Sviluppo delle gemme',
  'germination-grapes': '0. Germinazione/Sviluppo delle gemme',
  'germination-legumes': '0. Germinazione/Sviluppo delle gemme',
  'germination-rapeseed': '0. Germinazione/Sviluppo delle gemme',
  'germination-sorghum': '0. Germinazione/Sviluppo delle gemme',
  'germination-soybeans': '0. Germinazione/Sviluppo delle gemme',
  'germination-sugar-beet': '0. Germinazione/Sviluppo delle gemme',
  'germination-sunflower': '0. Germinazione/Sviluppo delle gemme',
  'grams': 'gr',
  'harvest_year': 'Anno del raccolto',
  'hectares': 'ha',
  'hectoliters-per-hectare': 'hl/ha',
  'images': 'Fotografie',
  'inches': 'in',
  'inflorescence-cereals': '5. Emergenza/intestazione delle infiorescenze',
  'inflorescence-corn': '5. Emergenza/intestazione delle infiorescenze',
  'inflorescence-fruits': '5. Emergenza/intestazione delle infiorescenze',
  'inflorescence-grapes': '5. Emergenza/intestazione delle infiorescenze',
  'inflorescence-legumes': '5. Emergenza/intestazione delle infiorescenze',
  'inflorescence-rapeseed': '5. Emergenza/intestazione delle infiorescenze',
  'inflorescence-sorghum': '5. Emergenza/intestazione delle infiorescenze',
  'inflorescence-sunflower': '5. Emergenza/intestazione delle infiorescenze',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Sviluppo delle foglie',
  'leaf-development-corn': '1. Sviluppo delle foglie',
  'leaf-development-fruits': '1. Sviluppo delle foglie',
  'leaf-development-grapes': '1. Sviluppo delle foglie',
  'leaf-development-legumes': '1. Sviluppo delle foglie',
  'leaf-development-rapeseed': '1. Sviluppo delle foglie',
  'leaf-development-sorghum': '1. Sviluppo delle foglie',
  'leaf-development-soybeans': '1. Sviluppo delle foglie',
  'leaf-development-sugar-beet': '1. Sviluppo delle foglie',
  'leaf-development-sunflower': '1. Sviluppo delle foglie',
  'loss_cause': 'Causa di perdita',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Numero di contratto',
  'policy_number': 'Numero di contratto',
  'ripening-cereals': '8. Maturazione o maturità del frutto e del seme',
  'ripening-corn': '8. Maturazione o maturità del frutto e del seme',
  'ripening-fruits': '8. Maturazione o maturità del frutto e del seme',
  'ripening-grapes': '8. Maturazione o maturità del frutto e del seme',
  'ripening-legumes': '8. Maturazione o maturità del frutto e del seme',
  'ripening-rapeseed': '8. Maturazione o maturità del frutto e del seme',
  'ripening-sorghum': '8. Maturazione o maturità del frutto e del seme',
  'ripening-soybeans': '8. Maturazione o maturità del frutto e del seme',
  'ripening-sunflower': '8. Maturazione o maturità del frutto e del seme',
  'senescence-fruits': '9. Senescenza, inizio della dormienza',
  'senescence-grapes': '9. Senescenza, inizio della dormienza',
  'senescence-sorghum': '9. Senescenza, inizio della dormienza',
  'senescence-soybeans': '9. Senescenza, inizio della dormienza',
  'senescence-sunflower': '9. Senescenza, inizio della dormienza',
  'side-shoot-cereals': '2. Formazione di germogli laterali/accestimento',
  'side-shoot-sorghum': '2. Formazione di germogli laterali/accestimento',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': 'Peso 1000 granelli (g)',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac²',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha²',
  'units-per-m2': '/m²',
  'usd': '$',
  'user_group': 'Portafoglio',
  'vegetative-growth-cereals': '3. Allungamento dello stelo/crescita della rosetta',
  'vegetative-growth-corn': '3. Allungamento dello stelo/crescita della rosetta',
  'vegetative-growth-fruits': '3. Allungamento dello stelo/crescita della rosetta',
  'vegetative-growth-legumes': '3. Allungamento dello stelo/crescita della rosetta',
  'vegetative-growth-rapeseed': '3. Allungamento dello stelo/crescita della rosetta',
  'vegetative-growth-sorghum': '3. Allungamento dello stelo/crescita della rosetta',
  'vegetative-growth-soybeans': '3. Allungamento dello stelo/crescita della rosetta',
  'vegetative-growth-sugar-beet': '3. Allungamento dello stelo/crescita della rosetta'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `Hai raggiunto il limite di ${k.count} farm memorizzate nella cache offline. Eliminane uno per procedere.`;
    case 'AdjusterSampledFarm': return `${k.added_by} azienda agricola campionata ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `Puoi allocare un'altra ${intl_num_2(k.remainingArea)}` + t(k.unit) + `.`;
    case 'AreaIsKm2': return `Quest'area è di ${intl_num(k.area)}km² (circa ${intl_int(k.mb)}MB).`;
    case 'CurFieldPolicyDesc': return `Contratto sul campo attuale: ${k.policy_number}`;
    case 'CurPortfolio': return `Portafoglio: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `Stai tentando di eliminare una visita assegnata anche ad altri ${k.count} LA.`;
    case 'DeletedUserX': return `Utente eliminato "${k.user_name}"`;
    case 'DistKmAway': return `${intl_num(k.dist_km)}km di distanza`;
    case 'DownloadExplain': return `Seleziona un'area da scaricare, per l'utilizzo offline. Verrà scaricato tutto nella mappa, a tutti i livelli di zoom. Tieni presente che puoi aggiungere ${intl_int(k.limit_km_2)}km² alla volta, per l'utilizzo offline.`;
    case 'DuplicateFieldId': return `Il campo ${k.external_field_id} è già stato aggiunto.`;
    case 'DuplicateSubplotWarning': return `Esiste già un ID della sottotrama: ${k.external_harvest_id} per questa azienda agricola e Anno del raccolto. Per aggiungervi un campione, modifica la selezione nella pagina precedente o scegli un altro ID di sottotrama.`;
    case 'EarsDetectedCount': return `${k.count} orecchie rilevate`;
    case 'ErrorDeletingUserXY': return `Errore durante l'eliminazione di "${k.user_name}": ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `Il rendimento stimato è stato sostituito l'ultima volta il ${k.date}. Nota che questa sostituzione sostituisce la resa stimata aggregata dai campioni sottostanti.`;
    case 'EstimatedYieldValueUnit': return `Stimato: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `Il raccolto esistente contiene ${k.count} campione/i.`;
    case 'ExoCropUnsupported': return `Questa funzione attualmente non supporta ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Fattibile: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Attenzione: questo campione verrà aggiunto solo al primo dei ${intl_int(k.numFields)} aggiunti.`;
    case 'FileSizeTooBig': return `Questo file è troppo grande. Seleziona un file inferiore a ${k.file_size} MB.`;
    case 'ImageLocationTooFar': return `L'immagine n.${k.imageIdx} è stata scattata a ${intl_num(k.distanceMeters)}` + t(k.unit) + ` dalla posizione campione.`;
    case 'InsuredYieldValueUnit': return `Assicurato: ${intl_num_2(k.val)}` + t(k.unit);
    case 'LossValue': return `Perdita: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `La somma delle perdite è troppo grande: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` superiore alla stima.`;
    case 'LossesToAllocate': return `Perdite da allocare: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` di ${intl_num_2(k.total)}` + t(k.unit) + ` perdita totale.`;
    case 'NFarmsSelected': return `${intl_int(k.numFarms)} aziende agricole selezionate`;
    case 'NPoliciesSelected': return `${intl_int(k.numPolicies)} criteri selezionati`;
    case 'NoAppInstalledToViewFile': return `Nessuna app installata per visualizzare ${k.file_name}`;
    case 'NoDeleteClaim': return `Prima di eliminare questa rivendicazione, devi eliminare le sue ${intl_int(k.visitCount)} visite.`;
    case 'NoDeleteFarm': return `Prima di eliminare questa farm, devi eliminare i relativi campi ${intl_int(k.fieldCount)}, campioni ${intl_int(k.sampleCount)} e attestazioni ${intl_int(k.claimCount)}.`;
    case 'NoDeleteField': return `Prima di eliminare questo campo, devi eliminare i relativi campioni ${intl_int(k.sampleCount)}.`;
    case 'NoDeleteHarvest': return `Prima di eliminare questo raccolto, devi eliminare i relativi campioni ${intl_int(k.sampleCount)}.`;
    case 'NoDeletePolicy': return `Prima di eliminare questa norma, devi eliminare le relative rivendicazioni ${intl_int(k.claimCount)}.`;
    case 'NumPhotos': return `${intl_int(k.num_photos)} Foto`;
    case 'PasswordResetSent': return `Un'e-mail di reimpostazione della password è stata inviata a ${k.email}`;
    case 'PlantDensityValueUnit': return `Densità delle piante: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Contratto ${k.policy_number}`;
    case 'ReportContainsTooManyFields': return `Questo rapporto potrebbe essere troppo grande, con ${intl_int(k.numFields)} campi. Considera la possibilità di selezionare un sottoinsieme di date campione e di raccolti.`;
    case 'ReportNoHarvests': return `Questa azienda agricola ha ${intl_int(k.numFields)} campi, ma non ha informazioni sul raccolto su cui riferire!`;
    case 'ResetMsg': return `Un'e-mail con un collegamento per la reimpostazione è stata inviata a ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} s.`;
    case 'SampleLocationTooFar': return `La posizione di esempio è ${intl_num_2(k.val)}` + t(k.unit) + ` dalla tua posizione di partenza. Se desideri utilizzare la tua posizione, premi "Usa la mia posizione" sopra.`;
    case 'SampleYieldWarning': return `Compila ${k.column1} o ${k.column2}, se possibile.`;
    case 'SearchAddTipDesc': return `Se desideri cercare più termini (es. raccolti di grano nel portafoglio ${k.firstUserGroup}) puoi premere sull'icona ⊕ per aggiungere un ulteriore termine.`;
    case 'SearchExampleDescUserGroup': return `Digita un nome di portafoglio (ad esempio ${k.firstUserGroup}) per mostrare tutti gli elementi in quel portafoglio`;
    case 'SearchFoundCount': return `Trova ${intl_int(k.farmCount)} farm, ${intl_int(k.fieldCount)} campi e ${intl_int(k.sampleCount)} campioni.`;
    case 'SearchInfoText': return `Puoi trovare aziende agricole, campi, campioni e contratti cercandoli qui. Se vuoi cercare più termini (es. raccolti di grano nel portfolio ${k.firstUserGroup}) puoi premere sull'icona ⊕. Ogni ricerca agirà quindi come un filtro che restringe ulteriormente i risultati. Vedi gli esempi seguenti per alcuni modi di ricerca.`;
    case 'SearchResultAddedBy': return `campioni aggiunti da ${k.id}`;
    case 'SearchResultAddress': return `Centra la mappa su ${k.address}`;
    case 'SearchResultCropCondition': return `campioni di raccolto con condizione ${k.id}`;
    case 'SearchResultFarm': return `azienda agricola ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `articoli per ${k.id}`;
    case 'SearchResultHarvestSeason': return `articoli per raccolti in ` + t(k.id);
    case 'SearchResultHarvestYear': return `articoli per raccolti di ${k.id}`;
    case 'SearchResultUserGroup': return `portafoglio ${k.id}`;
    case 'SelectCount': return `${k.count} selezionato`;
    case 'SortBy': return `Ordina ${k.column} ` + t(k.id);
    case 'SureAddAnotherHarvest': return `Sei sicuro di voler aggiungere un altro raccolto a questo campo, oltre a ${k.harvestDesc}?`;
    case 'SureDeleteFile': return `Sei sicuro di voler eliminare ${k.file_name}?`;
    case 'SureDeletePolicy': return `Sei sicuro di voler eliminare questo contratto? Questo contratto verrà rimosso dai campi ${intl_int(k.fieldCount)} e dagli esempi ${intl_int(k.sampleCount)}, ma non verranno eliminati.`;
    case 'SureRemoveFarm': return `Sei sicuro di voler eliminare questa azienda agricola e i suoi campi ${intl_int(k.fieldCount)}?`;
    case 'SureRemoveSelectedOfflineFarms': return `Sei sicuro di voler rimuovere l'accesso offline per ${k.count} aziende agricole selezionate?`;
    case 'TelepacReportEmail': return `Un rapporto sull'importazione ti è stato inviato a ${k.email}, puoi anche scaricarlo qui.`;
    case 'TotalAffectedAreaHarvest': return `L'area totale interessata da questo raccolto sarà ${intl_num_2(k.newArea)}` + t(k.unit) + ` su un'area di raccolto di ${intl_num_2(k.harvestArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt': return `L'attuale area interessata (campioni nelle ultime due settimane) per questo raccolto è: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `Queste sono tutte le aree interessate da questo raccolto: ${k.arr}.`;
    case 'TotalLossPct': return `Perdita totale: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `L'area totale della sottotrama (${intl_num_2(k.total_subplot_area)} ha) è più grande dell'area del campo (${intl_num_2(k.field_area)} ha).`;
    case 'UpdateHarvestAreaMsg': return `Questo aggiornerà la Area di ritaglio del raccolto corrente (${k.desc}) a ${intl_num_2(k.val)}` + t(k.unit) + `.`;
    case 'UpgradeFieldHarvest': return `Questo campo attualmente ha un raccolto ${k.originalHarvest}, che sarà sostituito con un raccolto ${k.upgradedHarvest}, per rispettare il contratto.`;
    case 'WarnHarvestAlreadyInUse': return `Il salvataggio delle modifiche a questo raccolto esistente inciderebbe su ${intl_int(k.num_samples)} campione/i già esistente/i.`;
    case 'WarningDupePolicyNumber': return `Attenzione: c'è un altro contratto con il numero ${k.policy_number}`;
    case 'YieldMismatchWarning': return `Non tutti i danni sono stati contabilizzati: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` mancante.`;
    case 'YourAreGoingToDeleteUsers': return `Stai per eliminare ${intl_int(k.num_users)} utenti:`;
    case 'downloadingLayers': return `Download di ${intl_int(k.count)} livelli per l'utilizzo offline.`;
    case 'uploadingImages': return `Caricamento di foto di esempio nel database (${intl_int(k.left)} left).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}