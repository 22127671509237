import {
  Claim,
  ClaimDamage,
  HarvestYear,
  Loss,
  VisitCandidate,
  VisitCandidateStatus,
  VisitLite,
} from '../models/interfaces';
import {getDistinctByKey, remove, uniqueByFastDeepEqual} from './arr-util';

export type VisitWithClaim = VisitLite & {claim: Claim | null; claim_damage: ClaimDamage[]};

export function extractCropIds(claim_damage: ClaimDamage[] | undefined | null): string[] {
  return [...new Set(claim_damage?.map(value => value.crop_id).filter(remove.nulls))];
}

export function extractCropIdsFromVisitWithClaim(visit: VisitWithClaim): string[] {
  return extractCropIds(visit.claim_damage);
}

export function extractHarvestYear(claim_damage: ClaimDamage[] | undefined | null): null | HarvestYear {
  return claim_damage?.[0]?.harvest_year ?? null;
}

export function extractInsuranceLossesFromVisitWithClaim(visit: VisitWithClaim): Loss[] {
  return extractInsuranceLosses(visit.claim_damage);
}

export function extractInsuranceLosses(claimDamages: ClaimDamage[]): Loss[] {
  return uniqueByFastDeepEqual(
    claimDamages.flatMap(claimDamage => claimDamage.insurance_loss_estimation).filter(remove.nulls),
  );
}

export function extractAssignedTo(
  assignedTo: Claim['assigned_to'] | undefined,
  statuses: null | VisitCandidateStatus[],
) {
  return (
    assignedTo
      ?.filter(
        assigned =>
          assigned?.visit_candidate_status && (!statuses || statuses.includes(assigned.visit_candidate_status)),
      )
      .filter(remove.nulls) ?? []
  );
}

export function extractAssignedToEmails(claim: Claim | null, statuses: null | VisitCandidateStatus[]): string[] {
  return (
    extractAssignedTo(claim?.assigned_to, statuses)
      .map(assigned => assigned?.email)
      .filter(remove.nulls) ?? []
  );
}

export function createVisitCandidates(emails: string[], status: VisitCandidateStatus) {
  return emails.map<VisitCandidate>(email => {
    return {
      email,
      visit_candidate_status: status,
      updated_at: null,
    };
  });
}

// assigned_to can be changed on the claims web view, claims app view and visit app view.
// - claims on the web: default status is assigned and LAs will either accept or reject it on the claims app view
// - visits in the app: default status is accepted and there is no notion of assigning and rejecting claims (visits)
// - claims in the app: claims can be accepted or rejected and are directly saved as such to db (not by this function)
// In any case, we must always preserve the rejected ones that didn't potentially change to assigned or accepted.
export function getUpdatedClaimAssignedTo(
  currentAssignedTo: Claim['assigned_to'],
  updatedAssignedTo: Claim['assigned_to'],
) {
  const updatedAssignedToEmails = updatedAssignedTo?.map(assignedTo => assignedTo?.email).filter(remove.nulls) ?? [];

  // keep only rejected harvests that are not in updated
  const existingAssignedTo = (currentAssignedTo ?? []).filter(
    (assignedTo: VisitCandidate | null) =>
      assignedTo?.email &&
      assignedTo.visit_candidate_status === 'rejected' &&
      !updatedAssignedToEmails.includes(assignedTo.email),
  );

  // always add all updatedAssignedTo and ensure uniqueness across email and status
  return getDistinctByKey([...existingAssignedTo, ...(updatedAssignedTo ?? [])], candidate =>
    JSON.stringify({email: candidate.email, visit_candidate_status: candidate.visit_candidate_status}),
  );
}
