import {AnalyticsI} from '../Analytics';
import {I18nFunction} from '../i18n/i18n';

// Formy is a homegrown system for managing forms in GT.
// A form calculates and submits a result, which we represent with the generic type F.

// Each form component will have different ways of representing state, which are opaque to Formy (any).
// Often, form components use strings (for text inputs), but may also use arrays (e.g. for a map selector)

// The FormyForm binds Formy with children components, and keeps track of their values.
// It's also responsible for validation (which has to happen at the form level, as there may be interdependencies.

// Optional values:
// Empty strings are coerced to null. If this is not desired, a validator can be added on that string/value,
// making it required, by setting a minimum length.

// Unchanged values:
// Formy will ignore errors on primitive fields that fail validation but are equal to the initial value.
// This is to make sure that editing a form does not fail because of fields that did not get changed.

export type FormyErrors<F> = {[Fk in keyof F]?: undefined | boolean | FormyErrors<F[Fk]>};

// FormyChanged can be an empty object, which is considered the same as unchanged
export type FormyChanged<F> = undefined | true | {[Fk in keyof F]?: FormyChanged<F[Fk]>};

export type FormyEventListener<F> = (field: keyof F, values: F, subfield?: keyof F[keyof F]) => void;

export type FormyOnSubmit<F> = (values: F, changedValues: FormyChanged<F>, formy: FormyI<F>) => Promise<void>;

export type SubmitEventHandler = () => Promise<boolean>;

// cb is called after the Formy state change has completed. Useful for doing form validation.
export type ChangeEventHandler<F, Fk extends keyof F> = (fieldValue: F[Fk]) => void;

export type BlurEventHandler = () => void;

export type FormyMode = 'new' | 'edit' | 'view';

export type FormyState = {
  mode: FormyMode;
};

export type FormyUpdatable = React.Component<unknown, unknown, unknown> | (() => void);

export const FormyRootRefKey = '__ROOT_REF__';
export type FormyRootRefKey = typeof FormyRootRefKey;

export type FormyElementRef = React.Component<any> | undefined;

// An externally provided callback that Formy can use to scroll to any error in the form, within a container `rootRef`.
export type FormyScrollToView = (rootRef: FormyElementRef, elementRefs: FormyElementRef[]) => Promise<void>;

export type FormyRefs<F> = {[P in keyof F]?: FormyRefs<F[keyof F]>} & {[P in FormyRootRefKey]: FormyElementRef};

export type CreateRefFn = (x: null | FormyElementRef) => void;

// Components hook into the Formy object to get handlers, current values, and translated strings.
export interface FormyI<F> {
  readonly t: I18nFunction;
  getSubmitHandler: () => SubmitEventHandler;

  setMode(mode: FormyMode): void;

  getChangeHandler(field: keyof F): ChangeEventHandler<F, typeof field>;

  getBlurHandler(field: keyof F): BlurEventHandler;

  // These accessors should be used carefully because the underlying data can change without the forms being
  // re-rendered. To render form values, useFormyValue or watchValue.
  getValues(): F;

  getValue<Fk extends keyof F>(field: Fk): F[Fk];

  getError<Fk extends keyof F>(field: Fk): undefined | boolean | FormyErrors<F>[Fk];

  // Returns a getter for this field's value. The watched component or function will be refreshed (resp. called)
  // when the field's value changes. Note that this will not trigger if any children values change, but will trigger
  // if the parent changes.
  // So, watchValue('x') will trigger if x changes, or if the dictionary in which 'x' in contained changes; but it will
  // not trigger if x.y changes.
  watchValue<Fk extends keyof F>(field: Fk, component: FormyUpdatable): () => F[Fk];

  // Returns a getter for a field's error state (true==error).
  // TODO(savv): If `field` is within a section that is in an error state, `field` itself won't be in an error state.
  watchError<Fk extends keyof F>(field: Fk, component: FormyUpdatable): () => boolean;

  // Returns a getter for the entire object. The watched component or function will be refreshed (resp. called)
  // when any of this object's columns changes.
  watchObj(component: FormyUpdatable): () => F;

  watchState(state: keyof FormyState, component: FormyUpdatable): Readonly<Pick<FormyState, typeof state>>;

  getSectionFormy<Fk extends keyof F>(field: Fk): FormyI<F[Fk]>;

  addOnChangeListener(listener: FormyEventListener<F>): void;

  addOnBlurListener(listener: FormyEventListener<F>): void;

  removeOnChangeListener(listener: FormyEventListener<F>): void;

  removeOnBlurListener(listener: FormyEventListener<F>): void;

  unwatch(component: FormyUpdatable): void;

  // Allows a formy instance to register its scrollTo handler.
  registerScrollToView(f: FormyScrollToView): void;

  // Allows a formy element to register itself for scrolling.
  createRef(field: keyof F | FormyRootRefKey): CreateRefFn;

  // Tells us if any values have changed.
  hasChangedValues(): boolean;

  // Tells us if a specific value has changed.
  hasChangedValue(field: keyof F): boolean;

  // Reset the form to its initial state.
  reset(): void;
}

export interface FormyInternals<F> {
  values: F;
  changedValues: FormyChanged<F>;
  initialValues: F;
  errors: FormyErrors<F>;
  refs: FormyRefs<F>;
  scrollView: FormyElementRef;
  state: {mode: FormyMode; isSubmitting: boolean};
  runOnChangeHandlers: (field: keyof F, subfield?: keyof F[keyof F]) => void;
  runOnBlurHandlers: (field: keyof F, subfield?: keyof F[keyof F]) => void;
  forceUpdate: (field: keyof F, sectionChanges: FormyChanged<F[keyof F]>) => void;
  validate: (x: F) => FormyErrors<F>;
}

export interface FormySectionInternals<G> {
  forceUpdate: (field: keyof G, sectionChanges: FormyChanged<G[keyof G]>) => void;
  forceModeUpdate: () => void;
}

export async function reportFormy<F>(analytics: AnalyticsI, _formy: FormyI<F>) {
  try {
    const formy = _formy as FormyI<F> & FormyInternals<F>;
    const name = (formy.getValues() as any)?.constructor?.name;
    analytics.logEvent({
      event_name: 'FormySubmit' + (name ? `-${name}` : ''),
      props: {
        mode: formy.state.mode,
        changedValues: formy.changedValues,
      },
    });
  } catch (e) {
    console.error('reportFormy failed', e);
  }
}

type ExcludeProperties<F, G> = {[P in keyof Omit<F, keyof G>]?: never} & Partial<G>;

export function keepChangedFields<F, G>(
  entity: ExcludeProperties<F, G>,
  changedFields: FormyChanged<F>,
): null | Partial<G> {
  const keys: (keyof F)[] = typeof changedFields == 'object' ? (Object.keys(changedFields) as (keyof F)[]) : [];
  if (keys.length === 0) {
    return null;
  }
  const updated: Partial<G> = {};
  for (const changedField of keys) {
    if (changedField in entity) {
      const key = changedField as any as keyof G;
      updated[key] = entity[key];
    }
  }
  return updated;
}
