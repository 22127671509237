import {FilePdfOutlined} from '@ant-design/icons';
import {Row, createColumnHelper} from '@tanstack/react-table';
import {Button, message} from 'antd';
import {saveAs} from 'file-saver';
import React, {useCallback, useMemo} from 'react';
import {
  GetVisitRowsRequest,
  GetVisitRowsRow,
  HarvestYear,
  VisitType,
  getVisitRows,
} from '../../../src/models/interfaces';
import {formatDate} from '../../../src/text/date';
import {extractAssignedToEmails} from '../../../src/util/claims';
import {filtersToRequest} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {EmailList} from '../components/EmailList';
import {InfinityTable, InfinityTableProps, defaultColumnSizes} from '../components/InfinityTable';
import SpinningDots from '../components/SpinningDots';
import {ErrorBoundary} from '../util/ErrorBoundary';
import {reportErr} from '../util/err';
import './Farms.css';
import {ExpanderCol} from './ListView';
import './ListView.css';

const columnHelper = createColumnHelper<GetVisitRowsRow>();

const initialSorting = {id: 'added_on', desc: true} as const;

const fetchSetSize: number = 100;

const getRowId = (row: GetVisitRowsRow): string => row.visit!.visit_id;
const getSortValue = (row: GetVisitRowsRow): string | null => row.visit?.added_on ?? null;
const getFarmName = (row: GetVisitRowsRow): string | undefined => row.farm!.farm_name ?? undefined;
const getHarvestYear = (row: GetVisitRowsRow): HarvestYear | undefined => row.claim?.harvest_year ?? undefined;
const getVisitType = (row: GetVisitRowsRow): VisitType | undefined => row.visit?.visit_type ?? undefined;
const getVisitDate = (row: GetVisitRowsRow): string | undefined =>
  row.visit?.closed_on ?? row.visit?.added_on ?? undefined;
const getVisitExperts = (row: GetVisitRowsRow): string[] => extractAssignedToEmails(row.claim, null);
const getVisitWithdrawal = (row: GetVisitRowsRow): boolean | undefined => row.visit?.withdrawal ?? undefined;

interface ReportInfo {
  visit_id: string;
  closed_on: string; // ISO date string.
  farm_name: string;
}

const getVisitReport = (row: GetVisitRowsRow): ReportInfo => {
  return {
    visit_id: row.visit!.visit_id,
    closed_on: row.visit?.closed_on ?? '',
    farm_name: row.farm?.farm_name ?? '',
  };
};

export default function Visits() {
  const {authedFetcher, t} = useApis();

  const onDownloadVisitReport = useCallback(
    async (info: ReportInfo) => {
      const {visit_id, closed_on, farm_name} = info;
      message.loading({
        content: t('Downloading') + '...',
        duration: 0,
        key: 'download',
        icon: <SpinningDots size={16} />,
      });

      let data: Uint8Array | null = null;
      try {
        data = await authedFetcher({
          method: 'GET',
          path: 'api/visit',
          headers: [['Accept', 'application/octet-stream']],
          params: [
            ['select', 'signed_report'],
            ['visit_id', 'eq.' + visit_id],
          ],
        });
      } catch (e) {
        reportErr(e, 'VisitReports');
      }

      if (!data) {
        return;
      }

      const fileName = `${t('VisitReport')}_${farm_name}_${closed_on?.slice(0, 10)}.pdf`;
      saveAs(new Blob([data]), fileName);
      message.success({content: t('Done'), duration: 2, key: 'download'});
    },
    [authedFetcher, t],
  );
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: '__details__',
        cell: ({row}) =>
          row.getCanExpand() && (
            <span onClick={() => row.toggleExpanded()} title={row.getIsExpanded() ? undefined : t('Details')}>
              <ExpanderCol.Expander isExpanded={row.getIsExpanded()} />
            </span>
          ),
        size: defaultColumnSizes.xxs,
        header: '', // Make sure to always return a string header for simpler handling downstream.
        enableResizing: false,
      }),
      columnHelper.accessor<typeof getFarmName, ReturnType<typeof getFarmName>>(getFarmName, {
        id: 'farm_name',
        header: t('FarmName'),
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        size: defaultColumnSizes.l,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getHarvestYear, ReturnType<typeof getHarvestYear>>(getHarvestYear, {
        id: 'harvest_year',
        header: t('HarvestYear'),
        size: defaultColumnSizes.xs,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
        enableResizing: true,
      }),
      columnHelper.accessor<typeof getVisitType, ReturnType<typeof getVisitType>>(getVisitType, {
        id: 'visit_type',
        header: t('VisitType'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => <span className="mask">{getValue() ? t(getValue()!) : '-'}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getVisitDate, ReturnType<typeof getVisitDate>>(getVisitDate, {
        id: 'visit_date',
        header: t('ReportDate'),
        size: defaultColumnSizes.s,
        cell: ({getValue}) => <span className="mask">{getValue() ? formatDate(t, getValue()!) : ''}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getVisitExperts, ReturnType<typeof getVisitExperts>>(getVisitExperts, {
        id: 'visit_expert',
        header: t('Expert'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => (
          <span className="mask">
            <EmailList emails={getValue()} />
          </span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getVisitWithdrawal, ReturnType<typeof getVisitWithdrawal>>(getVisitWithdrawal, {
        id: 'visit_withdrawal',
        cell: ({getValue}) => <span className="mask">{t(getValue() ? 'Yes' : 'No')}</span>,
        header: t('Withdrawal'),
        size: defaultColumnSizes.xs,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getVisitReport, ReturnType<typeof getVisitReport>>(getVisitReport, {
        id: 'visit_report',
        cell: ({getValue}) => (
          <Button
            type={'link'}
            icon={<FilePdfOutlined style={{color: 'red'}} />}
            onClick={() => onDownloadVisitReport(getValue())}
          />
        ),
        header: t('VisitReport'),
        size: defaultColumnSizes.xxs,
        enableSorting: false,
      }),
    ],
    [onDownloadVisitReport, t],
  );
  const fetchDataFromApi: InfinityTableProps<GetVisitRowsRow>['fetchData'] = useCallback(
    async ({filters, pageParam}): Promise<GetVisitRowsRow[]> => {
      const req: GetVisitRowsRequest = {
        ...filtersToRequest(filters),
        closed_state: true, // Only show closed visits.
        ordering: 'added_on-desc', // Only one ordering is supported for now.
        row_count: fetchSetSize,
        continue_from_val: pageParam?.continue_from_val ?? null,
        continue_from_id: pageParam?.continue_from_id ?? null,
      };
      return getVisitRows(authedFetcher, req);
    },
    [authedFetcher],
  );

  return (
    <span className="list-view list-visits">
      <ErrorBoundary>
        <InfinityTable<GetVisitRowsRow>
          columns={columns}
          initialSorting={initialSorting}
          fetchData={fetchDataFromApi}
          fetchSetSize={fetchSetSize}
          tableId="list/VisitReports"
          getRowId={getRowId}
          getSortValue={getSortValue}
          renderDetails={VisitDetails}
          indentDetails={1}
        />
      </ErrorBoundary>
    </span>
  );
}

const VisitDetails: React.FC<{row: Row<GetVisitRowsRow>}> = ({row}) => {
  const {t} = useApis();
  return (
    <div>
      {t('Comments')}: {row.original.visit?.comments ?? '-'}
    </div>
  );
};
