import {createSelector, defaultMemoize} from 'reselect';
import {UnreachableCaseError} from 'ts-essentials';
import fastDeepEqual from '../fast-deep-equal';
import {I18nFunction} from '../i18n/i18n';
import {intl_num} from '../i18n/i18n-util';
import {DensityUnit, DistanceUnit, HarvestCrop, UnitPriceUnit, WeightUnit, YieldUnit} from '../models/interfaces';
import {AreaUnit, AreaValue, DensityValue, DistanceValue, ValueUnit, WeightValue, YieldValue} from '../models/types';
import {DbMetaState} from '../redux/reducers/db';
import {Fruits} from './harvest';

// Source:
// https://www.ilga.gov/commission/jcar/admincode/008/00800600zz9998br.html
export const lbPerBushelByCrop: {[P in HarvestCrop]?: number} = {
  // 'alfalfa': 60,
  apples: 47,
  barley: 48,
  soybeans: 60,
  // NOTE: Wax is 24, white is 60.
  beans: 60,
  'sugar-beet': 60,
  // 'blue Grass Seed': 14,
  // 'bran': 20,
  buckwheat: 52,
  // 'carrots': 50,
  // 'charcoal': 20,
  // 'clover Seed': 60,
  'corn-grain': 56,
  // 'corn Seed, Broom': 48,
  // 'corn Meal, Unbolted': 48,
  // 'corn, in the ear': 70,
  // 'corn, Kaffir': 56,
  // 'corn, Shelled': 56,
  // 'cotton Seed': 32,
  // 'cranberries': 33,
  // 'cucumbers': 48,
  'other-vegetables': 48,
  // 'emmer': 40,
  // 'flax Seed': 56,
  // 'gooseberries': 40,
  // 'hemp Seed': 44,
  // 'hickory Nuts': 50,
  // 'hungarian Grass Seed': 50,
  // 'lime': 80,
  'barley-malting': 34,
  // 'millet': 50,
  // 'millet, Japanese Barnyard': 35,
  oats: 32,
  onions: 57,
  // 'onion Sets, Top': 30,
  // 'onion Sets, Bottom': 32,
  // 'orchard Grass Seed': 14,
  // 'osage Orange Seed': 33,
  // 'parsnips': 50,
  // 'peaches, Dried': 33,
  // 'peanuts, Green': 22,
  // 'pears': 58,
  // 'peas, Dried': 60,
  // 'peas, Green in pod': 32,
  // 'popcorn, in the ear': 70,
  // 'popcorn, shelled': 56,
  potatoes: 60, // Note: potatoes, Irish
  // 'potatoes, Sweet': 50,
  // 'quinces': 48,
  rapeseed: 50,
  // 'red Top Seed': 14,
  rice: 45, // Note: rough rice
  // 'rutabagas': 50,
  // 'rye Meal': 50,
  rye: 56,
  // 'shorts': 20,
  sorghum: 50, // Note: sorgum seed
  spelt: 40,
  // 'spinach': 12,
  sunflower: 30, // NOTE: taken from https://www.rayglen.com/crop-bushel-weights/
  // 'sweet Clover Seed Unhulled': 33,
  // 'timothy Seed': 45,
  tomatoes: 56,
  // 'turnips': 55,
  walnuts: 50,
  wheat: 60,
  'wheat-hard': 60, //  NOTE: We assumed this.
};

export const hectaresPerAcre = 0.404686;

export const gramsPerKilogram = 1000;

export const gramsPerOunce = 28.3495;

export const metersPerCm = 1 / 100;

export const metersPerInch = 0.0254;

export const metersPerFoot = 0.3048;

export const ft2PerMeterSq = 10.7639;

export const hectaresPerMeterSq = 1 / 10000;

export const acresPerMeterSq = 1 / 4046.86;

export const lbPerMetricTon = 2.20462 * 1000;

export const hectolitersPerTon = 8;

export const bagsPerTon = 1000 / 60;

export const arrobasPerTon = bagsPerTon * 4;

// https://www.cottonguide.org/cotton-guide/conversion-factors/
export const balesPerTon = 4.593;

export function convertLossToUnit(
  unit: YieldUnit,
  harvest_crop: null | HarvestCrop,
  feasible: null | YieldValue,
  loss: YieldValue,
): YieldValue {
  if (unit == loss.unit) {
    return loss;
  }

  const feasibleTHa = feasible && convertToTonsPerHectare(feasible.val, feasible.unit, harvest_crop);
  let lossTHa: null | number = null;
  if (loss.unit == 'percent') {
    if (feasibleTHa) {
      lossTHa = (loss.val / 100) * feasibleTHa;
    }
  } else {
    lossTHa = convertToTonsPerHectare(loss.val, loss.unit, harvest_crop);
  }

  if (unit == 'percent') {
    if (feasibleTHa && lossTHa != null) {
      return {unit, val: (lossTHa / feasibleTHa) * 100};
    }
  } else if (lossTHa != null) {
    const val = convertFromTonsPerHectare(lossTHa, unit, harvest_crop);
    if (val) {
      return {unit, val};
    }
  }

  return {unit, val: loss.val}; // couldn't calculate loss; keep same number, and change the unit.
}

export function convertToTonsPerHectare(
  value: number,
  fromUnit: YieldUnit,
  harvestCrop: null | undefined | HarvestCrop,
): null | number {
  if (fromUnit == 'bushels-per-acre') {
    const lbPerBushel = harvestCrop && lbPerBushelByCrop[harvestCrop];
    if (!lbPerBushel) {
      return null;
    }
    const bushelsPerTon = lbPerMetricTon / lbPerBushel;
    return value / bushelsPerTon / hectaresPerAcre;
  } else if (fromUnit == 'tons-per-hectare') {
    return value;
  } else if (fromUnit == 'decitons-per-hectare') {
    return value / 10;
  } else if (fromUnit == 'kilograms-per-hectare') {
    return value / 1000;
  } else if (fromUnit == 'hectoliters-per-hectare') {
    return value / hectolitersPerTon;
  } else if (fromUnit == 'bags-per-hectare') {
    return value / bagsPerTon;
  } else if (fromUnit == 'arrobas-bra') {
    return value / arrobasPerTon;
  } else if (fromUnit == 'bales-per-acre') {
    return value / balesPerTon;
  } else if (fromUnit == 'percent') {
    return null;
  } else if (fromUnit == 'tons-per-acre') {
    return value / hectaresPerAcre;
  } else {
    console.error(new UnreachableCaseError(fromUnit));
    return null;
  }
}

export function convertFromTonsPerHectare(
  value: number,
  toUnit: YieldUnit,
  harvestCrop: null | undefined | HarvestCrop,
): null | number {
  if (toUnit == 'bushels-per-acre') {
    const lbPerBushel = harvestCrop && lbPerBushelByCrop[harvestCrop];
    if (!lbPerBushel) {
      return null;
    }
    const bushelsPerTon = lbPerMetricTon / lbPerBushel;
    return value * bushelsPerTon * hectaresPerAcre;
  } else if (toUnit == 'tons-per-hectare') {
    return value;
  } else if (toUnit == 'decitons-per-hectare') {
    return value * 10;
  } else if (toUnit == 'kilograms-per-hectare') {
    return value * 1000;
  } else if (toUnit == 'hectoliters-per-hectare') {
    return value * hectolitersPerTon;
  } else if (toUnit == 'bags-per-hectare') {
    return value * bagsPerTon;
  } else if (toUnit == 'arrobas-bra') {
    return value * arrobasPerTon;
  } else if (toUnit == 'bales-per-acre') {
    return value * balesPerTon;
  } else if (toUnit == 'percent') {
    return null;
  } else if (toUnit == 'tons-per-acre') {
    return value * hectaresPerAcre;
  } else {
    console.error(new UnreachableCaseError(toUnit));
    return null;
  }
}

export function convertToHectares(value: number, fromUnit: AreaUnit): null | number {
  if (fromUnit == 'acres') {
    return value * hectaresPerAcre;
  } else if (fromUnit == 'hectares') {
    return value;
  } else {
    console.error(new UnreachableCaseError(fromUnit));
    return null;
  }
}

export function convertFromHectares(value: number, toUnit: AreaUnit): null | number {
  if (toUnit == 'acres') {
    return value / hectaresPerAcre;
  } else if (toUnit == 'hectares') {
    return value;
  } else {
    console.error(new UnreachableCaseError(toUnit));
    return null;
  }
}

function convertToGrams(value: number, fromUnit: WeightUnit): null | number {
  if (fromUnit == 'grams') {
    return value;
  } else if (fromUnit == 'thousand-kernel-weight-grams') {
    return value / 1000;
  } else if (fromUnit == 'ounces') {
    return value * gramsPerOunce;
  } else if (fromUnit == 'kilograms') {
    return value * gramsPerKilogram;
  } else {
    console.error(new UnreachableCaseError(fromUnit));
    return null;
  }
}

function convertFromGrams(value: number, toUnit: WeightUnit): null | number {
  if (toUnit == 'grams') {
    return value;
  } else if (toUnit == 'thousand-kernel-weight-grams') {
    return value * 1000;
  } else if (toUnit == 'ounces') {
    return value / gramsPerOunce;
  } else if (toUnit == 'kilograms') {
    return value / gramsPerKilogram;
  } else {
    console.error(new UnreachableCaseError(toUnit));
    return null;
  }
}

function convertToMeters(value: number, fromUnit: DistanceUnit): null | number {
  if (fromUnit == 'meters') {
    return value;
  } else if (fromUnit == 'centimeters') {
    return value * metersPerCm;
  } else if (fromUnit == 'inches') {
    return value * metersPerInch;
  } else if (fromUnit == 'feet') {
    return value * metersPerFoot;
  } else {
    console.error(new UnreachableCaseError(fromUnit));
    return null;
  }
}

function convertFromMeters(value: number, toUnit: DistanceUnit): null | number {
  if (toUnit == 'meters') {
    return value;
  } else if (toUnit == 'centimeters') {
    return value / metersPerCm;
  } else if (toUnit == 'inches') {
    return value / metersPerInch;
  } else if (toUnit == 'feet') {
    return value / metersPerFoot;
  } else {
    console.error(new UnreachableCaseError(toUnit));
    return null;
  }
}

function convertToUnitsPerM2(value: number, fromUnit: DensityUnit): null | number {
  if (fromUnit == 'units-per-m2') {
    return value;
  } else if (fromUnit == 'units-per-ft2') {
    return value * ft2PerMeterSq;
  } else if (fromUnit == 'units-per-hectare') {
    return value * hectaresPerMeterSq;
  } else if (fromUnit == 'units-per-acre') {
    return value * acresPerMeterSq;
  } else {
    console.error(new UnreachableCaseError(fromUnit));
    return null;
  }
}

function convertFromUnitsPerM2(value: number, toUnit: DensityUnit): null | number {
  if (toUnit == 'units-per-m2') {
    return value;
  } else if (toUnit == 'units-per-ft2') {
    return value / ft2PerMeterSq;
  } else if (toUnit == 'units-per-hectare') {
    return value / hectaresPerMeterSq;
  } else if (toUnit == 'units-per-acre') {
    return value / acresPerMeterSq;
  } else {
    console.error(new UnreachableCaseError(toUnit));
    return null;
  }
}

export function convertYield(
  unit: YieldUnit,
  value: null | YieldValue,
  harvestCrop: null | HarvestCrop,
): null | YieldValue {
  if (!value || value.unit == unit) {
    return value;
  }

  const tonsPerHectare = convertToTonsPerHectare(value.val, value.unit, harvestCrop);
  const newValue = tonsPerHectare && convertFromTonsPerHectare(tonsPerHectare, unit, harvestCrop);
  return newValue == null ? null : {unit: unit, val: newValue};
}

export function convertArea(unit: AreaUnit, value: null | AreaValue): null | AreaValue {
  if (!value || value.unit == unit) {
    return value;
  }

  const hectares = convertToHectares(value.val, value.unit);
  const newValue = hectares == null ? null : convertFromHectares(hectares, unit);
  return newValue == null ? null : {unit: unit, val: newValue};
}

export function convertWeight(unit: WeightUnit, value: null | WeightValue): null | WeightValue {
  if (!value || value.unit == unit) {
    return value;
  }

  const grams = convertToGrams(value.val, value.unit);
  const newValue = grams == null ? null : convertFromGrams(grams, unit);
  return newValue == null ? null : {unit: unit, val: newValue};
}

export function convertDistance(unit: DistanceUnit, value: null | DistanceValue): null | DistanceValue {
  if (!value || value.unit == unit) {
    return value;
  }

  const meters = convertToMeters(value.val, value.unit);
  const newValue = meters == null ? null : convertFromMeters(meters, unit);
  return newValue == null ? null : {unit: unit, val: newValue};
}

export function convertDensity(unit: DensityUnit, value: null | DensityValue): null | DensityValue {
  if (!value || value.unit == unit) {
    return value;
  }

  const unitsPerM2 = convertToUnitsPerM2(value.val, value.unit);
  const newValue = unitsPerM2 == null ? null : convertFromUnitsPerM2(unitsPerM2, unit);
  return newValue == null ? null : {unit: unit, val: newValue};
}

export interface UnitSystem {
  yieldUnit: YieldUnit;
  areaUnit: AreaUnit;
  densityUnit: DensityUnit;
  weightUnit: WeightUnit;
  distanceUnit: DistanceUnit;
  metric: boolean;
}

export const usaUnitSystem: UnitSystem = {
  areaUnit: 'acres',
  yieldUnit: 'bushels-per-acre',
  densityUnit: 'units-per-ft2',
  weightUnit: 'ounces',
  distanceUnit: 'feet',
  metric: false,
};

export const metricUnitSystem: UnitSystem = {
  areaUnit: 'hectares',
  yieldUnit: 'tons-per-hectare',
  densityUnit: 'units-per-m2',
  weightUnit: 'kilograms',
  distanceUnit: 'meters',
  metric: true,
};

export const braUnitSystem: UnitSystem = {
  areaUnit: 'hectares',
  yieldUnit: 'bags-per-hectare',
  densityUnit: 'units-per-m2',
  weightUnit: 'kilograms',
  distanceUnit: 'meters',
  metric: true,
};

export const ugaUnitSystem: UnitSystem = {
  areaUnit: 'acres',
  yieldUnit: 'tons-per-acre',
  densityUnit: 'units-per-m2',
  weightUnit: 'kilograms',
  distanceUnit: 'meters',
  metric: true,
};

export const marUnitSystem: UnitSystem = {
  areaUnit: 'hectares',
  yieldUnit: 'decitons-per-hectare',
  densityUnit: 'units-per-m2',
  weightUnit: 'kilograms',
  distanceUnit: 'meters',
  metric: true,
};

export const itaUnitSystem: UnitSystem = {
  areaUnit: 'hectares',
  yieldUnit: 'decitons-per-hectare',
  densityUnit: 'units-per-m2',
  weightUnit: 'kilograms',
  distanceUnit: 'meters',
  metric: true,
};

export const mexUnitSystem: UnitSystem = {
  areaUnit: 'hectares',
  yieldUnit: 'kilograms-per-hectare',
  densityUnit: 'units-per-m2',
  weightUnit: 'kilograms',
  distanceUnit: 'meters',
  metric: true,
};

const getDbMetaObj = (state: Readonly<{dbMeta: DbMetaState}>) => state.dbMeta;

export const getCountryCodeGroups = createSelector([getDbMetaObj], dbMeta =>
  dbMeta.userGroups.filter(x => x.user_group.match(/^[A-Z]{3}$/)).map(x => x.user_group),
);

// Note: Returns metric units if user_groups haven't been loaded yet.
export const getUnitSystem = createSelector(
  [getCountryCodeGroups],
  (countryCodes): UnitSystem =>
    countryCodes.length > 0 && countryCodes.every(x => x == 'USA' || x == 'CAN')
      ? usaUnitSystem
      : countryCodes.length > 0 && countryCodes.every(x => x == 'BRA')
        ? braUnitSystem
        : countryCodes.length > 0 && countryCodes.every(x => x == 'UGA')
          ? ugaUnitSystem
          : countryCodes.length > 0 && countryCodes.every(x => x == 'MAR')
            ? marUnitSystem
            : countryCodes.length > 0 && countryCodes.every(x => x == 'ITA')
              ? itaUnitSystem
              : countryCodes.length > 0 && countryCodes.every(x => x == 'MEX')
                ? mexUnitSystem
                : metricUnitSystem,
);

export function getIdealYieldUnit(units: UnitSystem, cropFamily: undefined | null | HarvestCrop): YieldUnit {
  if (cropFamily == 'grapes') {
    if (fastDeepEqual(units, itaUnitSystem)) {
      return 'decitons-per-hectare';
    } else if (units.metric) {
      return 'hectoliters-per-hectare';
    }
  }
  if (cropFamily == 'cotton') {
    if (fastDeepEqual(units, braUnitSystem)) {
      return 'arrobas-bra';
    } else if (fastDeepEqual(units, usaUnitSystem)) {
      return 'bales-per-acre';
    }
  }

  return units.yieldUnit;
}

export const getYieldUnits = defaultMemoize(
  (units: UnitSystem, harvest_crop: undefined | null | HarvestCrop): YieldUnit[] => [
    getIdealYieldUnit(units, harvest_crop),
  ],
);

export function getPriceUnits(harvest_crop: undefined | null | HarvestCrop): UnitPriceUnit[] {
  const priceUnits: UnitPriceUnit[] = ['eur-per-ton'];
  if (harvest_crop == 'grapes') {
    priceUnits[0] = 'eur-per-hectoliter';
  }

  return priceUnits;
}

export function unitStr(t: I18nFunction, x: null | ValueUnit) {
  if (!x) {
    return '-';
  }

  return `${intl_num(x.val)}` + t(x.unit);
}

export function roundValueUnit<T extends ValueUnit>(v: null | T): null | T {
  if (v) {
    v.val = Number(v.val.toFixed(2));
  }

  return v;
}

export function getIdealDensityUnit(cropFamily: HarvestCrop | null, densityUnit: DensityUnit): DensityUnit {
  if (cropFamily && Fruits.includes(cropFamily)) {
    return densityUnit == 'units-per-ft2'
      ? 'units-per-acre'
      : densityUnit == 'units-per-m2'
        ? 'units-per-hectare'
        : densityUnit;
  }

  return densityUnit;
}
