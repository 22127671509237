import {message} from 'antd';
import {ImportedData} from '../../../src/gt-pack/gt-pack';
import {_saveImportedData} from '../../../src/gt-pack/save';
import {Apis} from '../apis/Apis';
import {reportErr} from '../util/err';
import {getStateOrm} from './getStateOrm';

export async function saveImportedData(apis: Apis, data: ImportedData, updateExistingFarms: boolean) {
  try {
    return await _saveImportedData(apis.clock, getStateOrm(apis.store, apis), data, updateExistingFarms);
  } catch (e) {
    console.error('Unable to save:', e);
    message.error(apis.t('UnableToUpload'));
    reportErr(e);
  }
}
