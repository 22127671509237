import {ClockI} from '../Clock';
import {fastDeepEqual} from '../fast-deep-equal';
import {LngLat, getBoundingBox} from '../geo';
import {getUuid} from '../get-uuid';
import {FarmData} from '../models/data';
import {
  Claim,
  ClaimDamage,
  ClaimStatus,
  Farm,
  Field,
  Harvest,
  Policy,
  Visit,
  VisitCandidate,
} from '../models/interfaces';
import {TableName} from '../models/serialization';
import {AreaValue, OmitDbColumns} from '../models/types';
import {getCurYear} from '../selectors/year';
import {isSubsetOf, unique} from '../util/arr-util';
import {clearNullsInPlace, isObjEmpty} from '../util/obj-util';
import {ImportedData, getImportedFarmData, getImportedHarvestData} from './gt-pack';
import {StateOrm} from './stateOrm';

export function clearSame<T extends {custom_columns: any}>(update: Partial<T>, current: Partial<T>) {
  for (const k in update) {
    if (k == 'custom_columns') {
      const updatedCustomColumns = update.custom_columns,
        curCustomColumns = current.custom_columns;
      if (typeof updatedCustomColumns == 'object' && isObjEmpty(updatedCustomColumns) && curCustomColumns == null) {
        delete update.custom_columns;
      }
    } else if (fastDeepEqual(update[k], current[k])) {
      delete update[k];
    }
  }
}

async function findPolicy(stateOrm: StateOrm, policy_number: string | null) {
  if (policy_number && policy_number.length > 3) {
    let existingPolicies: Policy[] = await stateOrm.fetchEntitiesBy('policy', {
      column: 'policy_number',
      operator: 'eq',
      value: policy_number,
    });
    if (existingPolicies.length === 1) {
      return existingPolicies[0];
    }
  }
  return null;
}

export async function _saveImportedData(
  clock: ClockI,
  stateOrm: StateOrm,
  data: ImportedData,
  updateExistingFarms: boolean,
): Promise<{entity_type: TableName; entity_id: string}[]> {
  const farms: OmitDbColumns<Farm>[] = [];
  const policies: OmitDbColumns<Policy>[] = [];
  const fields: OmitDbColumns<Field>[] = [];
  const visits: OmitDbColumns<Visit>[] = [];
  const claims: OmitDbColumns<Claim>[] = [];
  const claimDamages: OmitDbColumns<ClaimDamage>[] = [];
  const harvests: OmitDbColumns<Harvest>[] = [];
  for (const importedFarm of data.farms) {
    let {farm_id, user_group, editors, policy_id} = importedFarm;
    const importedFarmDb = farm_id ? await stateOrm.getFarmById(farm_id) : null;

    if (!farm_id) {
      farm_id = getUuid();
    }
    // TODO(savv): consider moving this part of mergeImportedFarm.
    if (!user_group) {
      if (importedFarm.farm_id) {
        user_group = importedFarmDb?.user_group ?? null;
      }
    }
    if (!user_group) {
      console.error(importedFarm);
      throw new Error('No user group for imported farm');
    }

    if (importedFarm.farm_id) {
      if (updateExistingFarms) {
        const update: Partial<FarmData> = getImportedFarmData(importedFarm);
        clearNullsInPlace(update);
        delete update.user_group;
        // We might want to allow updates to add editors, after checking that they are in the right group
        // https://github.com/greentriangle/agro/pull/2216#discussion_r1118151660
        delete update.editors;
        importedFarmDb && clearSame(update, importedFarmDb);
        if (!isObjEmpty(update)) {
          await stateOrm.updateFarm(farm_id, update);
        }
      }
    } else {
      farms.push({farm_id, ...getImportedFarmData(importedFarm)});
    }

    // Try to find a preexisting policy by policy number.
    if (!importedFarm.policy_id) {
      const policy = await findPolicy(stateOrm, importedFarm.policy_number);
      if (policy) {
        policy_id = policy.policy_id;
        // automatically add editors to existing policy, less risky than for farms
        const policyEditors = unique([...policy.editors, ...editors]);
        if (!isSubsetOf(new Set(policyEditors), new Set(policy.editors))) {
          await stateOrm.updateEntity('policy', policy.policy_id, {editors: policyEditors});
        }
      }
    }

    if (!policy_id && importedFarm.policy_number) {
      policy_id = getUuid();

      policies.push({
        policy_id,
        user_group,
        editors,
        policy_number: importedFarm.policy_number,
        comments: importedFarm.policy_comments,
        metadata: null,
        custom_columns: null,
      });
    }

    // TODO(savv): add ImportedVisit.claim_id and use it to dedupe existing claims.
    const claim_id = importedFarm.visit ? getUuid() : null;
    const visitedHarvestIds = new Set<string>();
    for (const importedFarmHarvest of importedFarm.farmHarvests) {
      const harvestData = getImportedHarvestData(farm_id, null, importedFarmHarvest);
      let harvest_id;
      if (importedFarmHarvest.harvest_id) {
        harvest_id = importedFarmHarvest.harvest_id;
        clearNullsInPlace(harvestData);
        if (!isObjEmpty(harvestData)) {
          await stateOrm.updateEntity('harvest', importedFarmHarvest.harvest_id, harvestData);
        }
      } else {
        harvest_id = getUuid();
        harvests.push({...harvestData, harvest_id, policy_id});
      }

      if (claim_id && importedFarmHarvest.losses.length) {
        // This harvest has losses => it's affected. Add it to the visit's harvest_ids.
        visitedHarvestIds.add(harvest_id);

        // Add this harvest's losses as claim damages, unless this is pre-existing claim.
        // TODO(savv): this code may re-add a claim damage for a given harvest/loss, even if it already exists.
        //  We should avoid this.
        claimDamages.push({
          claim_damage_id: getUuid(),
          claim_id,
          harvest_id,
          field_id: null,
          harvest_year: importedFarmHarvest.harvest_year ?? getCurYear(clock),
          variety: importedFarmHarvest.variety,
          irrigated: importedFarmHarvest.irrigated,
          crop_id: importedFarmHarvest.crop_id ?? 'unknown',
          insurance_loss_estimation: importedFarmHarvest.losses,
          organic: importedFarmHarvest.organic,
          vegetation_stage: null,
        });
      }
    }

    for (const importedField of importedFarm.fields) {
      let field_location: null | LngLat = importedField.field_location;
      if (importedField.field_shape) {
        const bbox = getBoundingBox(importedField.field_shape);
        if (bbox) {
          const {ne, sw} = bbox;
          field_location = [(ne[0] + sw[0]) / 2, (ne[1] + sw[1]) / 2];
        }
      }
      const field_id = importedField.field_id ?? getUuid();
      const field = {
        farm_id,
        external_field_id: importedField.external_field_id,
        field_location,
        field_shape: importedField.field_shape,
        field_area: importedField.field_area as AreaValue,
        user_location: null,
        comments: null,
        metadata: null,
        custom_columns: null,
        region_id: null,
      };
      if (importedField.field_id) {
        clearNullsInPlace(field);
        if (!isObjEmpty(field)) {
          await stateOrm.updateField(field_id, field);
        }
      } else {
        fields.push({field_id, ...field});
      }

      for (const importedHarvest of importedField.harvests) {
        const harvestData = getImportedHarvestData(farm_id, field_id, importedHarvest);
        if (importedHarvest.harvest_id) {
          clearNullsInPlace(harvestData);
          if (!isObjEmpty(harvestData)) {
            await stateOrm.updateEntity('harvest', importedHarvest.harvest_id, harvestData);
          }
        } else {
          harvests.push({...harvestData, harvest_id: getUuid(), policy_id});
        }
      }
    }

    if (importedFarm.visit) {
      const visit_id = getUuid();
      if (importedFarm.visit.policy_number == importedFarm.policy_number) {
        importedFarm.visit.policy_id = policy_id;
      }
      if (!importedFarm.visit.policy_id) {
        const policy = await findPolicy(stateOrm, importedFarm.visit.policy_number);
        if (policy) {
          importedFarm.visit.policy_id = policy.policy_id;
        }
      }

      if (!claim_id) {
        // Should be unreachable, but necessary for type checking.
        throw new Error(`claim_id was null despite importedFarm.visit being present`);
      }
      const claim: OmitDbColumns<Claim> = {
        claim_id,
        farm_id,
        policy_id: importedFarm.visit.policy_id,
        harvest_year: importedFarm.visit.harvest_year,
        external_claim_id: importedFarm.visit.claim_number,
        assigned_to: importedFarm.visit.assigned_to.map<VisitCandidate>(assigned => {
          return {email: assigned, updated_at: null, visit_candidate_status: 'accepted'};
        }),
        metadata: null,
        comments: null,
        custom_columns: null,
        closed_on: null,
        manager_first_name: null,
        manager_last_name: null,
        manager_phone: null,
        manager_email: null,
        contact_first_name: null,
        contact_last_name: null,
        contact_phone: null,
        contact_email: null,
        coverage_type: null,
        status: 'adjuster-accepted' as ClaimStatus,
      };

      const visit: OmitDbColumns<Visit> = {
        visit_id,
        claim_id,
        sample_dates: [],
        visit_type: null,
        comments: null,
        withdrawal: null,
        withdrawal_crop_ids: null,
        signed_by_farmer: null,
        signature_crop_ids: null,
        signed_report: null,
        attachments: null,
        closed: false,
        metadata: importedFarm.visit.metadata,
        custom_columns: importedFarm.visit.custom_columns,
        closed_on: null,
        external_visit_id: importedFarm.visit.external_visit_id,
        visit_date: null,
        harvest_ids: [...visitedHarvestIds],
        visit_report_uri: null,
      };
      visits.push(visit);
      claims.push(claim);
    }
  }

  console.info(
    'Saving',
    farms.length,
    'farms',
    policies.length,
    'policies',
    fields.length,
    'fields',
    harvests.length,
    'harvests',
    visits.length,
    'visits',
    claims.length,
    'claims',
    claimDamages.length,
    'claim_damages',
  );
  await stateOrm.insertMany(farms, policies, fields, harvests, visits, claims, claimDamages);

  await stateOrm.commit();
  return [
    ...farms.map(x => ({entity_type: 'farm' as TableName, entity_id: x.farm_id})),
    ...policies.map(x => ({entity_type: 'policy' as TableName, entity_id: x.policy_id})),
    ...fields.map(x => ({entity_type: 'field' as TableName, entity_id: x.field_id})),
    ...harvests.map(x => ({entity_type: 'harvest' as TableName, entity_id: x.harvest_id})),
    ...visits.map(x => ({entity_type: 'visit' as TableName, entity_id: x.visit_id})),
    ...claims.map(x => ({entity_type: 'claim' as TableName, entity_id: x.claim_id})),
    ...claimDamages.map(x => ({entity_type: 'claim_damage' as TableName, entity_id: x.claim_damage_id})),
  ];
}
